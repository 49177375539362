import React, {useCallback, useMemo} from "react";
import redirect from 'nextjs-redirect'
import {useSelector} from "react-redux";
import {useRouter} from "next/router";

import { Button, Modal, Tooltip } from 'components'

import useModals from "state/modals/hooks";
import {AppState} from "state";

import {useGetPair, usePositionCardInformation} from "hooks/Pool";

const Redirect = redirect('/pool')

import styles from './poolDetails.module.scss'

const PoolDetailsModal: React.FC = () => {
  const router = useRouter();
  const { closeModals, openModal } = useModals();

  const {poolData, isUserPool} = useSelector((state: AppState) => state.pool);

  const pair = useGetPair(poolData)

  const {
    urlEnd,
    poolTokenPercentage,
    currency0,
    token0Deposited,
    token1Deposited,
    token0Rate,
    userPoolBalance,
    token1Rate,
    currency1
  } = usePositionCardInformation({pair})

  const disableRemovePool = useMemo(()=>{
    return !(userPoolBalance?.toSignificant(4) && parseFloat(userPoolBalance?.toSignificant(4)) > 0)
  }, [userPoolBalance])

  const handleAddPool = useCallback(() => {
    closeModals();
    router.push(`/pool/add/${urlEnd}`)
  }, [closeModals, router, urlEnd]);

  const handleDeletePool = useCallback(() => {
    closeModals();
    router.push(`/pool/remove/${urlEnd}`)
  }, [closeModals, router, urlEnd]);

  if (!poolData?.token0) {
    return  <Redirect>
      <></>
    </Redirect>
  }

  return (
    <Modal isOpen={true}
           title={{firstLine: `${isUserPool ? 'my' : ''} pool`, secondLine: 'details'}}
           description={'The property for all pools and adding liquidity in the HODL Valley network.'}
           mainIconName={"PoolOne"}>
      <div className={`h-full relative`}>
        <div className={`h-full flex flex-col`}>
          <div className={`w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative p-20px rounded-tr-60 ${styles.assetForm}`}>
            {poolData &&
              <div className={'flex'}>
                <div className={`${styles.leftColumn} pr-10px`}>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>Pool Share</p>
                    <p className={styles.value}>{poolTokenPercentage
                        ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                        : '-'}</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>{currency0.symbol} ADDED</p>
                    <div className={styles.valueWrapper}>
                      <Tooltip message={token0Deposited? token0Deposited?.toSignificant(6): ''}>
                        <p className={`${styles.value} truncate`}>{token0Deposited? token0Deposited?.toSignificant(6): '-'}</p>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>{currency1.symbol} ADDED</p>
                    <div className={styles.valueWrapper}>
                      <Tooltip message={token1Deposited? token1Deposited?.toSignificant(6): ''}>
                        <p className={`${styles.value} truncate max-w-full`}>{token1Deposited? token1Deposited?.toSignificant(6): '-'}</p>
                      </Tooltip>
                    </div>
                  </div>
                  {/*<div className={styles.infoRow}>*/}
                  {/*  <p className={styles.title}>DATE ADDED</p>*/}
                  {/*  <p className={styles.value}>{"DateTime.fromJSDate(poolData.addedAt).toFormat('LL/d/yyyy')"}</p>*/}
                  {/*</div>*/}
                </div>
                <div className={styles.rightColumn}>
                  {token0Rate? <>
                    <div className={styles.infoRow}>
                      <p className={styles.title}>Rates</p>
                    </div>
                    <div className={styles.infoRow}>
                      <div className={styles.value}>1 {currency0.symbol} =
                        <div className={`inline-flex px-5px truncate ${styles.valWrapper}`}>
                          <Tooltip message={token0Rate || ''}>
                            <p className={'truncate max-w-ful'}>{token0Rate.toFixed(4)}</p>
                          </Tooltip>
                        </div>
                        {currency1.symbol}
                      </div>
                    </div>
                  </>: null}

                  {token1Rate?<>
                    <div className={styles.infoRow}>
                      <p className={styles.title}>Rates</p>
                    </div>
                    <div className={styles.infoRow}>
                      <p className={styles.value}>
                        <div className={`inline-flex pr-5px truncate ${styles.valWrapper}`}>
                          <Tooltip message={token1Rate || ''}>
                            <p className={'truncate max-w-ful'}>{token1Rate.toFixed(4)}</p>
                          </Tooltip>
                        </div>
                        {currency1.symbol} = 1 {currency0.symbol}</p>
                    </div>
                  </>: null }

                </div>
              </div>
            }
          </div>
          <div className={`w-full relative z-10 ${styles.receiveForm}`}>
            <div className={'flex flex-col items-center'}>
              <p className={'text-40px text-blue-400 font-medium tracking-normal'}>{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</p>
              <p className={'text-xl text-gray-400 font-medium tracking-normal -mt-2px'}>{currency0?.symbol}/{currency1?.symbol} POOL TOKENS</p>
            </div>
            <div className={'flex justify-between items-center mt-45px'}>
              <Button onClick={handleAddPool}>
                <p className={'tracking-normal'}>ADD LIQUIDITY</p>
              </Button>
              <Button disabled={disableRemovePool} onClick={handleDeletePool}>
                <p className={`tracking-normal ${disableRemovePool ? 'text-gray-750' : 'text-white'}`}>Remove Pool</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PoolDetailsModal;
