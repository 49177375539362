import React, {useCallback, useMemo, useState} from 'react'
import Image from 'next/image'
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";

import {Button, Modal, TokenForm} from 'components'

import {Currency} from '@hodlvalley/sdk'

import {Field} from 'state/mint/actions'
import {useDerivedMintInfo, useMintSelectTokenHandlers} from 'state/mint/hooks'
import {addBookmarkPool} from "state/pool/actions";

import {useCurrency} from 'hooks/Tokens'
import {useActiveWeb3React} from "hooks/useActiveWeb3React";
import {showToast, ToastTypes} from "hooks/useToast";
import { useGetMyHodlPoolData } from 'hooks/Pool'

import styles from './importPool.module.scss'

const AddPoolModal: React.FC = () => {
	const { chainId } = useActiveWeb3React()
	const router = useRouter();
	const [currencyIdA, setCurrencyIdA] = useState<string>()
	const [currencyIdB, setCurrencyIdB] = useState<string>()

	const dispatch = useDispatch();
	const currencyA = useCurrency(currencyIdA)
	const currencyB = useCurrency(currencyIdB)
	const myPairs = useGetMyHodlPoolData()

	const {
		onHandleCurrencyASelect, onHandleCurrencyBSelect
	} = useMintSelectTokenHandlers(currencyIdA, currencyIdB)

	const handleChangeCurrency = useCallback((currency: Currency, isASide = false) => {
		if (isASide) {
			const { currencyIdA } = onHandleCurrencyASelect(currency)
			setCurrencyIdA(currencyIdA)
		} else {
			const { currencyIdB } = onHandleCurrencyBSelect(currency)
			setCurrencyIdB(currencyIdB)
		}
	}, [onHandleCurrencyASelect])


	const {
		currencies,
		pair
	} = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

	const isPoolAdded = useMemo(() => myPairs
			.some(x => x.token0.id.toLowerCase() === (pair?.token0?.address || '').toLowerCase() &&
				x.token1.id.toLowerCase() === (pair?.token1?.address || '').toLowerCase())
	, [myPairs, pair]);

	const handleAction = useCallback(()=>{
		if (isPoolAdded) {
			dispatch(addBookmarkPool({
				customPool: {
					chainId: chainId,
					lpAddress: pair.liquidityToken.address.toLowerCase()
				}
			}))
			showToast(`Added ${pair.token0.symbol}/${pair.token1.symbol} to my pools`, {
				type: ToastTypes.success
			})
		} else {
			router.push(`/pool/add/${pair.token0.address}/${pair.token1.address}`)
		}
	}, [pair, isPoolAdded])

	const buttonText = useMemo(()=>{
		if (!currencies[Field.CURRENCY_A] || !currencies[Field.CURRENCY_B]) {
			return 'Enter details'
		}

		return isPoolAdded ? 'Import' : 'Add Liquidity';
	}, [currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B], isPoolAdded])

	return (
		<>
			<Modal isOpen={true}
						 title={{ firstLine: 'import', secondLine: 'pool' }}
						 description={'The property for all pools and adding liquidity in the HODL Valley network.'}
						 mainIconName={'PoolOne'}>
				<div className={`h-full relative`}>
					<div className={`${styles.body} opacity-100'}`}>
						<div className={`h-full flex flex-col`}>
							<div className={`w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative p-20px rounded-tr-60 ${styles.assetForm}`}>
								<TokenForm
									operationTitle={'import'}
									selectedToken={currencies[Field.CURRENCY_A]}
									onChangeToken={(c: Currency) => handleChangeCurrency(c, true)}
									allowEditAmount={false}
									hideInput
									hideMax/>
								<div
									className={`absolute bg-gray-200 rounded-full overflow-hidden flex items-center justify-center border-10 border-gray-100 ${styles.swapForm}`}>
									<Image src={'/icons/plusSign.svg'} width={44} height={44} alt={'Plus'}/>
								</div>
							</div>
							<div className={`w-full relative z-10 ${styles.receiveForm}`}>
								<TokenForm
									operationTitle={'import'}
									selectedToken={currencies[Field.CURRENCY_B]}
									onChangeToken={(c: Currency) => handleChangeCurrency(c, false)}
									allowEditAmount={false}
									hideInput
									hideMax/>
							</div>
							<div className={'flex justify-end items-center mt-10px px-40px'}>
								<Button disabled={!currencies[Field.CURRENCY_A] || !currencies[Field.CURRENCY_B]} onClick={handleAction}>
									{buttonText}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default AddPoolModal
