import styles from './menu.module.scss'
import {MenuItemModel, MenuItemTypeModel} from "./index";
import {useCallback, useEffect, useRef, useState} from "react";
import useModals from '../../state/modals/hooks'
import {ApplicationModal} from "../../state/modals/actions";
import { useRouter } from 'next/router'

const Item: React.FC<{item: MenuItemModel}> = ({item}) => {
  const router = useRouter();

  const wrapperRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const { openModal } = useModals();

  const toggleMenu = useCallback(() => {
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  const handleItemClick = useCallback((item: MenuItemModel): void => {
    setShowDropdown(false);

    if (item.type && item.link !== undefined) {
      if (item.type === MenuItemTypeModel.EXTERNAL_LINK) {
        window.open(item.link.toString(), '_blank', 'noopener,noreferrer');
      }

      if (item.type === MenuItemTypeModel.MODAL) {
        openModal(item.link as ApplicationModal);
      }

      if (item.type === MenuItemTypeModel.LINK) {
        router.push(`${item.link}`);
      }
    }
  }, [openModal, router]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);


  return (
    <div className={'relative'}
         onClick={() => !!item?.subItems?.length && toggleMenu()}>
      <p className={`text-gray-400 font-medium text-sm cursor-pointer`} onClick={() => handleItemClick(item)}>{item.label}</p>
      {!!item?.subItems?.length &&
        <div className={'absolute top-0 left-50 inset-x-2/4 z-10'} ref={wrapperRef}>
          <div className={`${styles.menuDropdown} px-9px absolute border-white flex flex-col justify-end items-center bg-opacity-100 transition-all overflow-hidden duration-500 ease-in-out ${showDropdown ? `${styles.menuDropdownActive} max-h-screen border-4` : 'border-0 max-h-0 py-0'}`}>
            {item.subItems.map((subitem, index) => (
              <div className={`${styles.menuSubLinkWrapper} w-full bg-transparent transition last:mb-0 ${subitem?.link ? 'cursor-pointer hover:bg-white hover:bg-opacity-80' : 'cursor-not-allowed'}`}
                   key={index}
                   onClick={() => handleItemClick(subitem)}>
                <p className={`text-gray-400 font-medium text-sm ${subitem?.link ? 'text-opacity-100' : 'text-opacity-50'}`}>{subitem.label}</p>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default Item
