import React, {useCallback, useMemo, useState} from "react";
import {useRouter} from "next/router";
import {BackButton, Modal, ModalMenuItem, MenuItemTypeModel} from "components"

import {Sizes} from "types/Sizes";
import {LinkItemModal} from "types/LinkItemModal";

import useModals from "state/modals/hooks";

import {useGetAllHodlPoolData, useGetMyHodlPoolData} from "hooks/Pool";

import styles from './communityPool.module.scss'

interface PoolLinkItem extends LinkItemModal {
  count?: number;
}

const CommunityPoolModal: React.FC = () => {
  const router = useRouter();

  const { closeModals, openModal } = useModals();


  const allPairs = useGetAllHodlPoolData()
  const myPairs = useGetMyHodlPoolData()

  const [showDetail, setShowDetail] = useState(false);

  const list: PoolLinkItem[] = useMemo(()=> {
    return [
      { label: 'add pool', type: MenuItemTypeModel.LINK, link: '/pool/add/ETH' },
      { label: 'view my pools', type: MenuItemTypeModel.LINK, link: '/pool/myview', count: myPairs.length },
      { label: 'view all pools', type: MenuItemTypeModel.LINK, link: '/pool/allview', count: allPairs.length },
      { label: 'property details', type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
    ]
  }, [myPairs.length, allPairs.length])

  const toggleDetails = useCallback(() => {
    setShowDetail(!showDetail);
  }, [showDetail]);

  const selectModal = useCallback((item: LinkItemModal): void => {
    if (item.link) {
      if (MenuItemTypeModel.LINK === item.type) {
        closeModals();
      }
      if (item.type === MenuItemTypeModel.LINK) {
        router.push(`${item.link}`);
      }
      if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
        toggleDetails();
      }
    }
  }, [closeModals, openModal, toggleDetails]);

  return (
    <Modal isOpen={true}
           title={{firstLine:'community', secondLine: 'pool'}}
           description={'The property for all pools and adding liquidity in the HODL Valley network.'}
           size={Sizes.SMALL}
           mainIconName={'PoolOne'}>
      <div className={`h-full relative`}>
        <div className={`${styles.body} ${styles.bodyDetails} ${!showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'} flex flex-col justify-center`}>
          {list.map((item, index) => (
            <div className={'mb-20px'} key={index}>
              <ModalMenuItem label={item.label}
                             onSelect={() => selectModal(item)}
                             suffix={item.hasOwnProperty('count')
                               ? <p className={"ml-11px text-blue-400 tracking-normal font-medium text-xl"}>{item.count}</p>
                               : undefined} />
            </div>
          ))}
        </div>
        <div className={`${styles.body} ${styles.bodyDetails} ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <BackButton onClick={toggleDetails} />
          <div className={styles.detailsForm}>
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>NAME</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>Community Pool</p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>DESCRIPTION</p>
            <p className={`text-gray-400 text-xl tracking-normal mt-8px ${styles.detailDescription}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. </p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>AVAILABLE FOR PURCHASE</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>No</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CommunityPoolModal;
