import styles from './analytics.module.scss'
import {ReactNode, useCallback, useState} from "react";
import {useRouter} from "next/router";
import {SearchInput, NetworkListDropdown, BackButton} from "../../components";

interface LayoutProps {
  children: ReactNode;
}

const AnalyticsLayout: React.FC<LayoutProps> = ({ children}) => {
  const router = useRouter();

  const [search, setSearch] = useState<string>('');

  const handleBack = useCallback(() => {
    router.back();
  }, [router]);

  return (
    <div className={`flex justify-center pt-80px`}>
      <div className={`${styles.wrapper} border-10 border-white rounded-80 overflow-hidden`}>
        <div className={'flex items-start bg-white bg-opacity-80'}>
          <div className={'w-2/12 h-full h-10'}>

          </div>
          <div className={'w-10/12 pl-26px'}>
            <div className={'flex justify-between items-top pr-77px pt-50px'}>
              <BackButton onClick={handleBack} />
              <div className={'flex justify-end items-center'}>
                <div className={`${styles.searchWrapper}`}>
                  <SearchInput value={search} onChange={setSearch} />
                </div>
                <div className={`ml-25px rounded-full ${styles.tokenDropdownWrapper}`}>
                  <NetworkListDropdown />
                </div>
              </div>
            </div>
            <div className={'pt-49px pl-6px pr-78px'}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnalyticsLayout
