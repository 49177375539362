import { ReactNode, useEffect, useMemo, useState } from 'react'
import {useRouter} from "next/router";
import Image from 'next/image'

import {Menu, Popups} from "components"

import styles from './default.module.scss'

interface LayoutProps {
  blurBg?: boolean;
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, blurBg }) => {
  const router = useRouter();
  const { asPath } = useRouter()

  const [unityLoadProgress, setUnityLoadProgress] = useState(100);

  const showMap = useMemo(() => asPath === '/', [asPath]);

  useEffect(() => {
    const unityLoading = (progress: number) => {
      const correctProgress = Math.round(progress * 100 || 0);

      if (correctProgress < 100) {
        setUnityLoadProgress(correctProgress);
      } else {
        setTimeout(() => {
          setUnityLoadProgress(100);
        }, 2500);
      }
    }

    window.addEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', (e) => {
      unityLoading((e as any).detail?.progress);
    }, false)

    return window.removeEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', () => unityLoading)
  }, [])

  return (
    <div className={`z-0 flex flex-col items-center w-full min-h-screen overflow-x-hidden overflow-y-auto bg-valley-pattern bg-no-repeat bg-cover bg-center relative`}>
      <div className={`fixed overflow-hidden w-full h-full z-100 bg-valley-loading-pattern bg-no-repeat bg-cover bg-center flex flex-col items-center justify-center ${unityLoadProgress < 100 ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
        <Image src={'/images/HODLValleyRibbonLogo.png'} alt="HODL Valley" width={650} height={536} />
        <div className={`p-8px ${styles.loader}`}>
          <div className={`${styles.loaderForm} w-full h-full relative flex items-center pl-40px overflow-hidden`}>
            <div className={`${styles.loaderActiveProgress} h-full absolute top-0 left-0`} style={{width: `calc(${unityLoadProgress}% - 4px)`}}></div>
            <p className={'text-2xl uppercase font-medium text-white text-center relative z-10 tracking-normal w-full'}>loading {unityLoadProgress}%...</p>
          </div>
        </div>
      </div>
      <div className={`absolute overflow-visible z-50 ${styles.logoWrapper}`} onClick={() => router.push("/")}>
        <Image src={'/icons/HodlLogo.svg'} alt="HODL Valley" width={280} height={42} />
      </div>
      <div className={`absolute z-50 ${styles.menuWrapper}`}>
        <Menu />
      </div>
      {/*<div className={`absolute z-50 ${styles.mapWrapper} ${showMap ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>*/}
      {/*  <Map />*/}
      {/*</div>*/}
      <Popups />
      {/*<div className={`fixed w-full h-full top-0 left-0 bg-transparent transition-opacity duration-500 ${unityLoadProgress === 100 ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>*/}
      {/*  <iframe src="/hodlUnityNew/index.html" frameBorder="0" className={'w-full h-full'} />*/}
      {/*</div>*/}
      <main className={`w-full min-h-screen max-h-screen overflow-y-scroll pb-50px ${blurBg ? 'blurWrapper' : ''} ${styles.contentWrapper}`}>
      {/*<main className={`w-full min-h-screen pb-50px ${blurBg ? 'blurWrapper' : ''} ${styles.contentWrapper}`}>*/}
        {children}
      </main>
    </div>
  )
}

export default Layout
