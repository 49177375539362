/* eslint-disable jsx-a11y/alt-text */
import Image from 'next/image'

import styles from "./toast.module.scss"
import {ToastTypes} from "../../hooks/useToast";

interface ToastProps {
  text: string;
  type?: ToastTypes;
  autosize?: boolean;
}

const Toast: React.FC<ToastProps> = ({text, type = ToastTypes.error, autosize = false}) => (
  <div className={`flex items-center rounded-full border-5 pt-11px pb-8px pl-30px pr-80px w-full 
                  ${type === ToastTypes.success ? `${styles.wrapperSuccess} bg-green-400 border-green-500` : `${styles.wrapperError} bg-red-400 border-red-300`}
                  ${!autosize ? styles.large : ''}`}>
    <div className={'cursor-pointer pt-4px'}>
      {type === ToastTypes.success ?
        <Image src={"/icons/iconSuccess.svg"} width={48} height={42} />
        :
        <Image src={"/icons/iconWarning.svg"} width={48} height={42} />
      }
    </div>
    <hr className={`${styles.delimiter} ml-24px`} />
    <p className={`text-base text-white font-medium tracking-wider px-32px ${styles.text}`}>{text}</p>
    <hr className={styles.delimiter} />
    <div className={'cursor-pointer'}>

    </div>
  </div>
);

export default Toast;
