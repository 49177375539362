import styles from './modalMenuItem.module.scss'
import {ReactNode, useCallback, useState} from "react";

interface ModalMenuItemProps {
  label: string;
  onSelect?: () => void;
  disabled?: boolean;
  suffix?: ReactNode;
}

const ModalMenuItem: React.FC<ModalMenuItemProps> = ({label, onSelect, disabled = false, suffix}) => {
  const [hover, setHover] = useState(false);

  const handleSelect = useCallback(() => {
    onSelect && onSelect();
  }, [onSelect]);

  return (
    <div className={`border-bg-white w-full rounded-full flex items-center cursor-pointer transition-opacity text-opacity-60 text-gray-400 hover:text-opacity-100 hover:bg-white hover:border-white ${disabled ? 'bg-gray-250 border-0' : 'bg-gray-50 border-3'} ${styles.btn}`}
         onClick={handleSelect}
         onMouseEnter={ ()=> setHover(true) }
         onMouseLeave={ ()=> setHover(false) }>
      <p className={'uppercase text-xl font-medium overflow-ellipsis overflow-hidden w-full whitespace-nowrap'}>{label}</p>
      {suffix &&
        <div className={`${hover ? 'opacity-100' : 'opacity-60'}`}>
          {suffix}
        </div>
      }
    </div>
  )
}

export default ModalMenuItem
