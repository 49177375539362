import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Image from 'next/image'
import {BigNumber} from '@ethersproject/bignumber'
import {TransactionResponse} from '@ethersproject/providers'
import {Currency, CurrencyAmount, Percent} from '@hodlvalley/sdk'

import {Button, Modal, TokenForm, DoubleCurrencyLogo} from 'components'

import {Field} from 'state/mint/actions'
import {useDerivedMintInfo, useMintActionHandlers, useMintSelectTokenHandlers, useMintState} from 'state/mint/hooks'
import {useExpertModeManager, useUserSlippageToleranceWithDefault} from 'state/user/hooks'
import {useTransactionAdder} from 'state/transactions/hooks'

import { useCurrency } from 'hooks/Tokens'
import {useActiveWeb3React} from 'hooks/useActiveWeb3React'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import {ApprovalState, useApproveCallback, useRouterContract} from 'hooks'
import {useIsSwapUnsupported} from 'hooks/useIsSwapUnsupported'
import {showToast, ToastTypes} from "hooks/useToast";

import TransactionConfirmationModal, {
	ConfirmationModalContent,
	TransactionType
} from 'modals/TransactionConfirmationModal'

import {ConfirmAddModalBottom} from 'features/liquidity/ConfirmAddModalBottom'

import { calculateGasMargin, calculateSlippageAmount, maxAmountSpend } from 'functions'

import {ZERO_PERCENT} from '../../constants'

import styles from './addPool.module.scss'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

interface AddPoolModalProps {
	tokenAddresses?: string[]
}

const AddPoolModal: React.FC<AddPoolModalProps> = ({tokenAddresses = []}) => {
	const { account, chainId, library } = useActiveWeb3React()
	const routerContract = useRouterContract()
	const addTransaction = useTransactionAdder()
	const deadline = useTransactionDeadline() // custom from users settings
	const [isExpertMode] = useExpertModeManager()

	const [currencyIdA, setCurrencyIdA] = useState<string>(tokenAddresses?.length ? tokenAddresses[0] : undefined)
	const [currencyIdB, setCurrencyIdB] = useState<string>(tokenAddresses?.length > 1 ? tokenAddresses[1] : undefined)

	const [showSettings, setShowSettings] = useState(false)
	const [showConfirm, setShowConfirm] = useState<boolean>(false)
	const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
	const [txHash, setTxHash] = useState<string>('')

	const currencyA = useCurrency(currencyIdA)
	const currencyB = useCurrency(currencyIdB)

	const {
		onHandleCurrencyASelect, onHandleCurrencyBSelect
	} = useMintSelectTokenHandlers(currencyIdA, currencyIdB)

	const handleChangeCurrency = useCallback((currency: Currency, isASide = false) => {
		if (isASide) {
			const { currencyIdA } = onHandleCurrencyASelect(currency)
			setCurrencyIdA(currencyIdA)
		} else {
			const { currencyIdB } = onHandleCurrencyBSelect(currency)
			setCurrencyIdB(currencyIdB)
		}
	}, [onHandleCurrencyASelect])

	// const oneCurrencyIsWETH = Boolean(
	//     chainId &&
	//     ((currencyA && currencyEquals(currencyA, WNATIVE[chainId])) ||
	//         (currencyB && currencyEquals(currencyB, WNATIVE[chainId])))
	// )
	//
	// const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

	// mint state
	const { independentField, typedValue, otherTypedValue } = useMintState()
	const {
		dependentField,
		currencies,
		pair,
		pairState,
		currencyBalances,
		parsedAmounts,
		price,
		noLiquidity,
		liquidityMinted,
		poolTokenPercentage,
		error
	} = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

	const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

	// const [allowedSlippage] = useUserSlippageTolerance(); // custom from users

	const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users

	// get formatted amounts
	const formattedAmounts = useMemo(() => ({
		[independentField]: typedValue,
		[dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
	}), [independentField, typedValue, dependentField, noLiquidity, otherTypedValue, parsedAmounts, dependentField])

	// get the max amounts user can add
	const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
		(accumulator, field) => {
			return {
				...accumulator,
				[field]: maxAmountSpend(currencyBalances[field])
			}
		},
		{}
	)

	// const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
	//     (accumulator, field) => {
	//       return {
	//         ...accumulator,
	//         [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
	//       }
	//     },
	//     {}
	// )

	// check whether the user has approved the router on the tokens
	const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], routerContract?.address)
	const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], routerContract?.address)

	const pendingText = useMemo(() => {
		return `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
			currencies[Field.CURRENCY_A]?.symbol
		} and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`
	}, [parsedAmounts, currencies])

	const isValid = useMemo(() => !error, [error])

	const onAdd = async () => {
		if (!chainId || !library || !account || !routerContract) return

		const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
		if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
			return
		}

		const amountsMin = {
			[Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
			[Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0]
		}

		let estimate,
			method: (...args: any) => Promise<TransactionResponse>,
			args: Array<string | string[] | number>,
			value: BigNumber | null
		if (currencyA.isNative || currencyB.isNative) {
			const tokenBIsETH = currencyB.isNative
			estimate = routerContract.estimateGas.addLiquidityETH
			method = routerContract.addLiquidityETH
			args = [
				(tokenBIsETH ? currencyA : currencyB)?.wrapped?.address ?? '', // token
				(tokenBIsETH ? parsedAmountA : parsedAmountB).quotient.toString(), // token desired
				amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
				amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
				account,
				deadline.toHexString()
			]
			value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString())
		} else {
			estimate = routerContract.estimateGas.addLiquidity
			method = routerContract.addLiquidity
			args = [
				currencyA?.wrapped?.address ?? '',
				currencyB?.wrapped?.address ?? '',
				parsedAmountA.quotient.toString(),
				parsedAmountB.quotient.toString(),
				amountsMin[Field.CURRENCY_A].toString(),
				amountsMin[Field.CURRENCY_B].toString(),
				account,
				deadline.toHexString()
			]
			value = null
		}

		setAttemptingTxn(true)
		await estimate(...args, value ? { value } : {})
			.then((estimatedGasLimit) =>
				method(...args, {
					...(value ? { value } : {}),
					gasLimit: calculateGasMargin(estimatedGasLimit)
				}).then((response) => {
					setAttemptingTxn(false)

					addTransaction(response, {
						summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
							currencies[Field.CURRENCY_A]?.symbol
						} and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`
					})

					setTxHash(response.hash)


				})
			)
			.catch((error) => {

				setShowConfirm(false);
				setAttemptingTxn(false)
				// we only care if the error is something _other_ than the user rejected the tx
				if (error?.code !== 4001) {
					console.error(error)

				}
				showToast(error.message, {
					type: ToastTypes.error,
					timeout: 7 * 1000
				})
			})
	}

	useEffect(()=>{
		if (showConfirm) {
			onAdd()
		}
	}, [showConfirm]);

	const handleDismissConfirmation = useCallback(() => {
		setShowConfirm(false)
		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onFieldAInput('')
		}
		setTxHash('')
	}, [onFieldAInput, txHash])

	const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

	const modalHeader = useCallback(() => {
		return noLiquidity ? (
			<div className="pb-4">
				<div className="flex items-center justify-start gap-3">
					<div className="text-2xl font-bold text-high-emphesis">
						{currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
					</div>
					<DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={48}/>
				</div>
			</div>
		) : (
			<div className="pb-4">
				<div className="flex items-center justify-start gap-3">
					<div className="text-xl font-bold md:text-3xl text-high-emphesis">{liquidityMinted?.toSignificant(6)}</div>
					<div className="grid grid-flow-col gap-2">
						<DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={48}/>
					</div>
				</div>
				<div className="text-lg font-medium md:text-2xl text-high-emphesis">
					{currencies[Field.CURRENCY_A]?.symbol}/{currencies[Field.CURRENCY_B]?.symbol}
					&nbsp;{`Pool Tokens`}
				</div>
				<div className="pt-3 text-xs italic text-secondary">
					{`Output is estimated. If the price changes by more than ${allowedSlippage.toSignificant(
						4
					)}% your transaction
            will revert.`}
				</div>
			</div>
		)
	}, [noLiquidity, currencies, currencyA, currencyB])

	const modalBottom = useCallback(() => (<ConfirmAddModalBottom
			price={price}
			currencies={currencies}
			parsedAmounts={parsedAmounts}
			noLiquidity={noLiquidity}
			onAdd={onAdd}
			poolTokenPercentage={poolTokenPercentage}
		/>), [price, currencies, parsedAmounts, noLiquidity, onAdd, poolTokenPercentage])

	const getPoolFormDetailsConfig = useCallback(() => {
		return  {
			title: 'POOL DETAILS',
			infoColumns: [
				// { title: 'DATE ADDED', value: '12/1/2020' },
				{ title: 'POOL SHARE', value: noLiquidity ? '100 %' : `${poolTokenPercentage ? poolTokenPercentage?.toSignificant(4) : ''} %` },
				{ title: 'RATE', value: parsedAmounts[Field.CURRENCY_A]? `1 ${parsedAmounts[Field.CURRENCY_A]?.currency.symbol} = ${price?.toSignificant(4)} ${
						parsedAmounts[Field.CURRENCY_B]?.currency.symbol
					}` : ''},
				{ title: 'RATE', value: parsedAmounts[Field.CURRENCY_B]? `1 ${parsedAmounts[Field.CURRENCY_B]?.currency.symbol} = ${price?.invert()?.toSignificant(4)} ${
						parsedAmounts[Field.CURRENCY_A]?.currency.symbol
					}` : ''}
			]
		}
	}, [noLiquidity, poolTokenPercentage, parsedAmounts, price]);

	const textCTA = useMemo(() => {
		if (addIsUnsupported) {
			return "Unsupported Asset"
		}

		if (isValid) {
			if (approvalA !== ApprovalState.APPROVED) {
				return approvalA === ApprovalState.PENDING
					? `Approving ${currencies[Field.CURRENCY_A]?.symbol}`
					: `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
			}

			if (approvalB !== ApprovalState.APPROVED) {
				return approvalB === ApprovalState.PENDING
					? `Approving ${currencies[Field.CURRENCY_B]?.symbol}`
					: `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
			}
		}

		if (approvalA === ApprovalState.APPROVED && approvalB === ApprovalState.APPROVED) {
			return error ?? `Confirm Adding Liquidity`
		}

		return "Enter details"
	}, [addIsUnsupported, isValid, approvalA, approvalB, currencies, error])

	const disabledCTA = useMemo(() => {
		if (addIsUnsupported) {
			return true;
		}

		if (isValid) {
			if (approvalA !== ApprovalState.APPROVED) {
				return approvalA === ApprovalState.PENDING;
			}

			if (approvalB !== ApprovalState.APPROVED) {
				return approvalB === ApprovalState.PENDING;
			}
		}

		if (approvalA === ApprovalState.APPROVED && approvalB === ApprovalState.APPROVED) {
			return !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
		}

		return true;
	}, [addIsUnsupported, isValid, approvalA, approvalB, currencies, error])

	const errorCTA = useMemo(() => {
		if (approvalA === ApprovalState.APPROVED && approvalB === ApprovalState.APPROVED) {
			return !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B];
		}

		return false;
	}, [approvalA, approvalB, isValid, parsedAmounts]);

	const handleCTA = useCallback(() => {
		if (addIsUnsupported) {
			// do nothing
		} else if (isValid) {
			if (approvalA !== ApprovalState.APPROVED) {
				return approveACallback();
			}

			if (approvalB !== ApprovalState.APPROVED) {
				return approveBCallback();
			}
		}

		if (approvalA === ApprovalState.APPROVED && approvalB === ApprovalState.APPROVED) {
			setShowConfirm(true)
		}
	}, [addIsUnsupported, isValid, approvalA, approvalB, approveACallback, approveBCallback])

	return (
		<>
			<Modal isOpen={!showConfirm}
						 title={{ firstLine: 'add', secondLine: 'pool' }}
						 description={'The property for all pools and adding liquidity in the HODL Valley network.'}
						 formDetailsConfig={getPoolFormDetailsConfig()}
						 mainIconName={'PoolOne'}>
				<div className={`h-full relative`}>
					<div className={`${styles.body} ${showSettings ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
						<div className={`h-full flex flex-col`}>
							<div className={`w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative p-20px rounded-tr-60 ${styles.assetForm}`}>
								<TokenForm
									operationTitle={'add'}
									selectedToken={currencies[Field.CURRENCY_A]}
									onChangeToken={(c: Currency) => handleChangeCurrency(c, true)}
									amount={formattedAmounts[Field.CURRENCY_A]}
									onChangeAmount={onFieldAInput}
									allowEditAmount={true}
									onMax={() => {
										onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
									}}
									readonly={false}/>
								<div
									className={`absolute bg-gray-200 rounded-full overflow-hidden flex items-center justify-center border-10 border-gray-100 ${styles.swapForm}`}>
									<Image src={'/icons/plusSign.svg'} width={44} height={44} alt={'Plus'}/>
								</div>
							</div>
							<div className={`w-full relative z-10 ${styles.receiveForm}`}>
								<TokenForm
									onMax={() => {
										onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
									}}
									operationTitle={'add'}
									selectedToken={currencies[Field.CURRENCY_B]}
									onChangeToken={(c: Currency) => handleChangeCurrency(c, false)}
									amount={formattedAmounts[Field.CURRENCY_B]}
									onChangeAmount={onFieldBInput}
									allowEditAmount={true}
									readonly={false}/>
							</div>
							<div className={'flex justify-end items-center mt-10px px-40px'}>
								<Button disabled={disabledCTA} error={errorCTA} onClick={handleCTA}>
									{textCTA}
								</Button>

								{/*{addIsUnsupported ?*/}
								{/*	<Button disabled>*/}
								{/*		<p className={approving ? 'text-opacity-50' : ''}>Unsupported Asset</p>*/}
								{/*	</Button>*/}
								{/*	:*/}
								{/*	(isValid && <>*/}
								{/*		{approvalA !== ApprovalState.APPROVED &&*/}
								{/*		<Button*/}
								{/*			onClick={approveACallback}*/}
								{/*			disabled={approvalA === ApprovalState.PENDING}*/}
								{/*		>*/}
								{/*			<p>*/}
								{/*				{approvalA === ApprovalState.PENDING ? (*/}
								{/*					`Approving ${currencies[Field.CURRENCY_A]?.symbol}`*/}
								{/*				) : (*/}
								{/*					`Approve ${currencies[Field.CURRENCY_A]?.symbol}`*/}
								{/*				)}*/}
								{/*			</p>*/}
								{/*		</Button>*/}
								{/*		}*/}
								{/*		{approvalB !== ApprovalState.APPROVED && (*/}
								{/*			<Button*/}
								{/*				onClick={approveBCallback}*/}
								{/*				disabled={approvalB === ApprovalState.PENDING}*/}
								{/*			>*/}
								{/*				<p>*/}
								{/*					{approvalB === ApprovalState.PENDING ? (*/}
								{/*						`Approving ${currencies[Field.CURRENCY_B]?.symbol}`*/}
								{/*					) : (*/}
								{/*						`Approve ${currencies[Field.CURRENCY_B]?.symbol}`*/}
								{/*					)}*/}
								{/*				</p>*/}
								{/*			</Button>*/}
								{/*		)}*/}
								{/*	</>)}*/}
								{/*{(approvalA === ApprovalState.APPROVED && approvalB === ApprovalState.APPROVED) &&*/}
								{/*<Button*/}
								{/*	onClick={() => {*/}
								{/*		setShowConfirm(true)*/}
								{/*		// isExpertMode ? onAdd() : setShowConfirm(true)*/}
								{/*	}}*/}
								{/*	disabled={*/}
								{/*		!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED*/}
								{/*	}*/}
								{/*	error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}*/}
								{/*>*/}
								{/*	<p className={approving ? 'text-opacity-50' : ''}>*/}
								{/*		{error ?? `Confirm Adding Liquidity`}*/}
								{/*	</p>*/}
								{/*</Button>}*/}
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<TransactionConfirmationModal
				type={TransactionType.ADD_POOL}
				isOpen={showConfirm}
				onDismiss={handleDismissConfirmation}
				attemptingTxn={attemptingTxn}
				hash={txHash}
				content={() => (
					<ConfirmationModalContent
						title={noLiquidity ? `You are creating a pool` : `You will receive`}
						onDismiss={handleDismissConfirmation}
						topContent={modalHeader}
						bottomContent={modalBottom}
					/>
				)}
				pendingText={pendingText}
				token0Symbol={currencies[Field.CURRENCY_A]?.symbol}
				token1Symbol={currencies[Field.CURRENCY_B]?.symbol}
				token0Amount={parsedAmounts[Field.CURRENCY_A]}
				token1Amount={parsedAmounts[Field.CURRENCY_B]}
			/>
		</>
	)
}

export default AddPoolModal
