import {BackButton, Modal, ModalMenuItem} from "../../components"

import styles from './stakeHome.module.scss'
import {Sizes} from "../../types/Sizes";
import React, {useCallback, useState} from "react";
import useModals from "../../state/modals/hooks";
import {useRouter} from "next/router";
import {MenuItemTypeModel} from "../../components/Menu";
import {LinkItemModal} from "../../types/LinkItemModal";

const list: LinkItemModal[] = [
  { label: 'add stake', type: MenuItemTypeModel.LINK, link: '/stake/add' },
  { label: 'remove stake', type: MenuItemTypeModel.LINK, link: '/stake/remove' },
  { label: 'view staking stats', type: MenuItemTypeModel.LINK },
  { label: 'property details', type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
]

const StakeHomeModal: React.FC = () => {
  const router = useRouter();
  const { closeModals, openModal } = useModals();

  const [showDetail, setShowDetail] = useState(false);

  const toggleDetails = useCallback(() => {
    setShowDetail(!showDetail);
  }, [showDetail]);

  const selectModal = useCallback((item: LinkItemModal): void => {
    if (item.link) {
      if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
        closeModals();
      }
      if (item.type === MenuItemTypeModel.LINK) {
        router.push(`${item.link}`);
      }
      if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
        toggleDetails();
      }
    }
  }, [closeModals, openModal, toggleDetails]);

  return (
    <Modal isOpen={true}
           title={{firstLine:'community', secondLine: 'mine'}}
           description={'The property for the staking of the MONEY token in the HODL Valley network.'}
           size={Sizes.SMALL}
           mainIconName={"Mine"}>
      <div className={`h-full relative`}>
        <div className={`${styles.body} ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
          <div className={`h-full flex flex-col justify-center`}>
            {list.map((item, index) => (
              <div className={'mb-20px'} key={index}>
                <ModalMenuItem label={item.label} onSelect={() => selectModal(item)} />
              </div>
            ))}
          </div>
        </div>
        <div className={`${styles.body} ${styles.bodyDetails} ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <BackButton onClick={toggleDetails} />
          <div className={styles.detailsForm}>
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>NAME</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>Community Mine</p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>DESCRIPTION</p>
            <p className={`text-gray-400 text-xl tracking-normal mt-8px ${styles.detailDescription}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. </p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>AVAILABLE FOR PURCHASE</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>No</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StakeHomeModal;
