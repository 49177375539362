import { Modal, PoolItem } from 'components'
import Image from 'next/image'
import styles from './searchPool.module.scss'
import { Sizes } from 'types/Sizes'
import React, { useCallback, useEffect, useState } from 'react'
import useModals from 'state/modals/hooks'
import usePool from 'state/pool/hooks'
import { useRouter } from 'next/router'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FixedSizeList } from 'react-window'
import {useGetAllHodlPoolData, useGetMyHodlPoolData, useGetPoolData} from 'hooks/Pool'
import {CurrencyAmount, Pair, Token} from '@hodlvalley/sdk'
import useDebounce from 'hooks/useDebounce'
import {useActiveWeb3React} from "hooks/useActiveWeb3React";

interface SearchPoolOrFarmModalProps {
	isMyPool?: boolean;
	isFarmView?: boolean;
	pairs
}

const SearchPoolOrFarmModal: React.FC<SearchPoolOrFarmModalProps> = ({ isMyPool = false, pairs }) => {
	const router = useRouter()
	const { closeModals, openModal } = useModals()
	const { setPool } = usePool()
	const { chainId } = useActiveWeb3React()
	const [searchVal, setSearchVal] = useState('')

	const debouncedSearchVal = useDebounce(searchVal, 250)




	const selectItem = useCallback((item): void => {
		closeModals()

		const urlEnd = `${item.token0.id}-${item.token0.id}`
		setPool(item, isMyPool)

		router.push(`/pool/${isMyPool ? 'myview' : 'allview'}/${urlEnd}`)
	}, [closeModals, isMyPool, router])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			const item = data[index]


			const tokenA = new Token(chainId, item.token0.id, 18, item.token0.symbol, item.token0.name);
			const tokenB = new Token(chainId, item.token1.id, 18, item.token1.symbol, item.token1.name);

			const currencyAmountA = CurrencyAmount.fromRawAmount(tokenA, '0');
			const currencyAmountB = CurrencyAmount.fromRawAmount(tokenB, '0');

			const pair = new Pair(currencyAmountA, currencyAmountB);

			return (
				<div key={data[index].id} style={style} className={'px-10px'}>
					<PoolItem item={pair} onSelect={() => selectItem(data[index])}/>
				</div>
			)
		},
		[selectItem]
	)

	return (
		<Modal isOpen={true}
					 title={{ firstLine: isMyPool ? 'view my' : 'view all', secondLine: isMyPool ? 'pool' : 'pools' }}
					 description={'The property for all pools and adding liquidity in the HODL Valley network.'}
					 size={Sizes.MEDIUM}
					 mainIconName={'PoolOne'}>
			<div className={`h-full flex flex-col ${styles.form}`}>
				<div className={'px-10px'}>
					<div
						className={`w-full h-66px bg-gray-220 border-3 border-white bg-opacity-60 border-opacity-60 rounded-full flex justify-between items-center pl-43px pr-26px pt-2px ${styles.searchWrapper}`}>
						<input className={'bg-transparent font-medium text-xl text-gray-700 placeholder-gray-700'}
									 placeholder={'SEARCH...'}
									 type="text"
									 value={searchVal}
									 onChange={(e) => setSearchVal(e.target.value)}/>
						<div className={'mt-5px'}>
							<Image src={'/icons/iconMagnifier.svg'} width={25} height={25}/>
						</div>
					</div>
				</div>
				<div className={`mt-20px ${styles.listWrapper}`}>
					<FixedSizeList
						outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
						height={408}
						itemData={pairs}
						itemCount={pairs.length}
						itemSize={86}
						width={'100%'}
					>
						{RenderRow}
					</FixedSizeList>

					{/*{(isFarmView ? farmList : poolList).map((item, index) => (*/}
					{/*  <div className={'mb-20px'} key={index}>*/}
					{/*    <PoolFarmItem item={item} isFarm={isFarmView} onSelect={() => selectItem(item)} />*/}
					{/*  </div>*/}
					{/*))}*/}
				</div>
			</div>
		</Modal>
	)
}

export default SearchPoolOrFarmModal
