import { Currency, TradeType, Trade as V2Trade } from '@hodlvalley/sdk'
import React, { ReactNode } from 'react'

import { Button } from '../../components'
import { SwapCallbackError } from './styleds'

export default function SwapModalFooter({
  trade,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: V2Trade<Currency, Currency, TradeType>
  onConfirm: () => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
}) {
  return (
    <div className="p-6 mt-0 -m-6 rounded bg-dark-800">

      <Button
        onClick={onConfirm}
        disabled={disabledConfirm}
      >
        Confirm Swap
      </Button>

      {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
    </div>
  )
}
