import Image from 'next/image'

import styles from './notification.module.scss'
import { useEffect, useRef } from 'react'

export enum NotificationType {
	NORMAL = 'NORMAL',
	SUCCESS = 'SUCCESS',
	WARNING = 'WARNING'
}

export type NotificationModel = {
	id?: string,
	text: string,
	linkText: {
		text: string,
		link: string
	},
	type: NotificationType;
}

interface Props {
	item: NotificationModel;
	showTime?: number;
}

const Notification: React.FC<Props> = ({ item, showTime }) => {
	const progressLineRef = useRef<HTMLDivElement>()

	useEffect(() => {
		if (progressLineRef.current) {
			progressLineRef.current.style.width = '100%';

			setTimeout(() => {
				progressLineRef.current.style.width = '0'
			}, 10)
		}
	}, [])

	return (
		<div
			className={`${styles.wrapper} border-4 flex items-center
		${item.type === NotificationType.SUCCESS
				? 'bg-green-400 border-green-500'
				: item.type === NotificationType.WARNING
					? 'bg-red-400 border-red-300'
					: 'bg-transparent border-white border-opacity-40'}`}>
			<div className={`flex justify-center items-center ${styles.notificationIcon}`}>
				{(item.type === NotificationType.SUCCESS || item.type === NotificationType.NORMAL) &&
				<Image src={'/icons/iconSuccess.svg'} alt="Success" width={49} height={49}/>
				}
				{item.type === NotificationType.WARNING &&
				<Image src={'/icons/iconWarning.svg'} alt="Warning" width={49} height={49}/>
				}
			</div>
			<hr className={`h-full bg-white bg-opacity-20 border-0 ${styles.delimiter}`}/>
			<div className={`flex flex-col space-between w-1/2 ${styles.body}`}>
				<p className={`font-bold text-sm text-white uppercase w-full truncate ${styles.title}`}>{item.text}</p>
				<p className={`font-bold text-sm text-white text-opacity-70 cursor-pointer w-full truncate ${styles.link}`}
					 onClick={() => item?.linkText?.link && window.open(item.linkText.link, '_blank')}>
					{item.linkText.text}
				</p>
				{showTime &&
				<div className={`relative w-full bg-white bg-opacity-20 rounded ${styles.progress}`}>
					<div className={`absolute top-0 left-0 h-full bg-white rounded h-full ${styles.progressLineAnimation}`}
							 ref={progressLineRef} style={{ transitionDuration: `${showTime}ms` }}/>
				</div>
				}
			</div>
			<div className={`flex justify-center items-center cursor-pointer ${styles.closeWrapper}`}/>
		</div>
	)
}

export default Notification
