import { AppDispatch, AppState } from 'state'
import { useIsListActive } from 'state/lists/hooks'
import { disableList, enableList, removeList } from 'state/lists/actions'
import styles from "./tokenDropdown.module.scss"
import ListLogo from 'components/ListLogo'
import Image from 'next/image'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'components/index'

const ListRow: React.FC<{ listUrl: string; style: any }> = ({ listUrl, style }) => {
	const listsByUrl = useSelector<AppState, AppState['lists']['byUrl']>((state) => state.lists.byUrl)
	const dispatch = useDispatch<AppDispatch>()
	const { current: list, pendingUpdate: pending } = listsByUrl[listUrl]

	const isActive = useIsListActive(listUrl)

	const handleRemoveList = useCallback(() => {
		dispatch(removeList(listUrl))
	}, [dispatch, listUrl])

	const handleEnableList = useCallback(() => {
		dispatch(enableList(listUrl))
	}, [dispatch, listUrl])

	const handleDisableList = useCallback(() => {
		dispatch(disableList(listUrl))
	}, [dispatch, listUrl])

	if (!list) return null

	return (
		<div className={'flex items-center justify-between pb-0px border-b-3 border-gray-260 mb-18px last:border-0 last:mb-0'} style={style}>
			<div className={'flex items-center w-1/2'}>
				<ListLogo size="32px" logoURI={list.logoURI} alt={`${list.name} list logo`} />
				<div className={`flex flex-col pl-8px pt-2px ${styles.tokenNameWrapper}`}>
					<div className={'w-full truncate max-w-full'}>
						<Tooltip message={list.name}>
							<span className={'uppercase text-sm text-gray-400 font-bold truncate max-w-full'}>{list.name}</span>
						</Tooltip>
					</div>
					<p className={'uppercase text-15px text-gray-400 font-bold pt-1px'}>{list.tokens.length} Tokens</p>
				</div>
			</div>
			<div className={'flex items-center justify-end -mt-9px w-1/2'}>
				<div className={'mr-24px flex items-center justify-center'} onClick={handleRemoveList}>
					<Image src={'/icons/iconCrossTiny.svg'} alt={'delete'} width={21} height={21} />
				</div>
				<a className={'mr-18px mt-3px'} href={`https://tokenlists.org/token-list?url=${listUrl}`} target={"_blank"} rel="noreferrer">
					<Image src={'/icons/iconShare.svg'} alt={'share'} width={29} height={29} />
				</a>
				<button
				  onClick={() => isActive ? handleDisableList() : handleEnableList()}
				  className={`uppercase font-medium text-sm flex justify-center items-center -mt-3px ${styles.onOffbutton} ${isActive ? 'text-white bg-gray-400' : 'text-gray-310 border-3 border-gray-270'}`}>
				  {isActive ? 'On' : 'Off'}
				</button>
			</div>
		</div>
	)
}

export default ListRow;
