import styles from './poolItem.module.scss'
import { useCallback } from 'react'
import Image from 'next/image'
import { unwrappedToken } from '../../functions'
import {getTokenIconFromID, getTokenIconFromName} from 'analytics/core/format'
import { CurrencyAmount, JSBI, Pair, Percent } from '@hodlvalley/sdk'
import { useTokenBalance } from 'state/wallet/hooks'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { useTotalSupply } from 'hooks/useTotalSupply'

interface PoolItemProps {
	item: Pair;
	onSelect?: () => void;
}

const PoolItem: React.FC<PoolItemProps> = ({ item, onSelect }) => {
	const handleSelect = useCallback(() => {
		onSelect && onSelect()
	}, [onSelect])

	const { account, chainId } = useActiveWeb3React()
	const pair = item

	const token0ImageUrl = getTokenIconFromName({ symbol: pair.token0.symbol })
	const token1ImageUrl = getTokenIconFromName({ symbol: pair.token1.symbol })

	const currency0 = unwrappedToken(pair.token0)
	const currency1 = unwrappedToken(pair.token1)

	const stakedBalance = CurrencyAmount.fromRawAmount(item.liquidityToken, '0')
	const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
	// if staked balance balance provided, add to standard liquidity amount
	const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

	const totalPoolTokens = useTotalSupply(pair.liquidityToken)

	const poolTokenPercentage =
		!!userPoolBalance &&
		!!totalPoolTokens &&
		JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
			? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
			: undefined

	return (
		<div
			className={`bg-gray-50 h-66px border-3 border-bg-white rounded-full flex items-center cursor-pointer ${styles.btn} transition-opacity text-opacity-60 text-gray-400 hover:text-opacity-100 hover:bg-white`}
			onClick={handleSelect}>
			<div>
				<Image src={token0ImageUrl} width={25} height={25} alt={currency0.name}/>
			</div>
			<div className={'ml-10px'}>
				<Image src={token1ImageUrl} width={25} height={25} alt={currency1.name}/>
			</div>
			<p className={'ml-14px uppercase font-medium text-xl text-gray-400 text-opacity-60'}>
				{currency0.symbol} / {currency1.symbol}</p>
			<p className={'ml-22px font-medium text-xl text-blue-400'}>
				{poolTokenPercentage
					? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
					: '-'}
			</p>
		</div>
	)
}

export default PoolItem
