import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from "next/router";

import { Button, Modal, TokenDropdown, TokenDropdownTypes, Dots } from "components"
import {Sizes} from "types/Sizes";

import useSWR from 'swr'
import { request } from 'graphql-request'
import { CurrencyAmount, JSBI, STAKING_ADDRESS, ZERO } from '@hodlvalley/sdk'

import { useTokenBalance } from "state/wallet/hooks";
import { MONEY, XMONEY } from "../../constants";
import { tryParseAmount } from "functions";
import { ApprovalState, useApproveCallback, useSushiBar, useActiveWeb3React } from "hooks";
import useReserveContract from "features/stake/useReserve";

import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionType
} from "modals/TransactionConfirmationModal";

import styles from './addRemoveStake.module.scss'
import { Confirmation } from 'modals'

interface AddRemoveStakeModalProps {
  isRemove: boolean;
}
const INPUT_CHAR_LIMIT = 18
const sendTx = async (txFunc: () => Promise<any>): Promise<boolean> => {
  let success = true
  try {
    const ret = await txFunc()
    const { hash } = ret;
    if (ret?.error) {
      return  false
    }
    return  hash
  } catch (e) {
    console.error(e)
    success = false
  }
  return success
}

const AddRemoveStakeModal: React.FC<AddRemoveStakeModalProps> = ({isRemove}) => {
  const activeTab = isRemove ? 1 : 0
  const router = useRouter();
  const { account, chainId } = useActiveWeb3React()
  const moneyBalance = useTokenBalance(account ?? undefined, MONEY[chainId])
  const xMoneyBalance = useTokenBalance(account ?? undefined, XMONEY[chainId])
  const { enter, leave } = useSushiBar()
  const { data } = useSWR(`{bar(id: "${XMONEY[chainId].address.toLowerCase()}") {ratio, totalSupply}}`,
    (query) =>
      request('https://thegraph.com/explorer/subgraph/ameesha1205/staking-staging', query)
  )
  const { moneyBalance: moneyReserveBalance } = useReserveContract()

  const [hash, setHash] = useState('')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)
  const [input, setInput] = useState<string>('')
  const [stakePercent, setStakePercent] = useState('0');
  const [apr, setApr] = useState(0);

  const walletConnected = useMemo(() => !!account, [account]);

  const balance = activeTab === 0 ? moneyBalance : xMoneyBalance

  const parsedAmount = useMemo(() => tryParseAmount(input, balance?.currency), [balance, input])

  const [approvalState, approve] = useApproveCallback(parsedAmount, STAKING_ADDRESS[chainId])

  const insufficientFunds = (balance && balance.equalTo(ZERO)) || parsedAmount?.greaterThan(balance)

  const inputError = insufficientFunds

  const buttonDisabled = (parseFloat(input) <= 0) || pendingTx || (parsedAmount && parsedAmount.equalTo(ZERO))




  // TODO: DROP AND USE SWR HOOKS INSTEAD
  useEffect(() => {
    const totalReserve = moneyReserveBalance? parseFloat(moneyReserveBalance.toSignificant(6)): 0;

    const stakingRewards = totalReserve * 0.3
    const totalStaked = data?.bar?.totalSupply? data?.bar?.totalSupply: 1
    setApr((stakingRewards / totalStaked) * 100)
  }, [data?.bar?.totalSupply, moneyReserveBalance])

  useEffect(() => {
    if (balance?.currency) {
      const correctNum = Number(stakePercent);

      const unstakedBalance = moneyBalance ? moneyBalance : CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt('0'))
      const stakedBalance = xMoneyBalance ? xMoneyBalance : CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt('0'))

      const activeAmount = isRemove? stakedBalance: unstakedBalance

      const num = CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt(`${correctNum}`))
      const full = CurrencyAmount.fromRawAmount(balance?.currency, JSBI.BigInt(`${100}`))
      const percent = num.divide(full);

      const newMoney = (activeAmount.multiply(percent)).toSignificant(6);
      if (newMoney.length <= INPUT_CHAR_LIMIT) {
        setInput(newMoney)
      }
    }
  }, [stakePercent, moneyBalance, xMoneyBalance, isRemove])

  const handleClickButton = async () => {
    if (buttonDisabled) return

    if (!walletConnected) {
      router.push('/connect')
      return;
    } else {
      setPendingTx(true)

      if (activeTab === 0) {
        if (approvalState === ApprovalState.NOT_APPROVED) {
          const success = await sendTx(() => approve())
          if (!success) {
            setPendingTx(false)
            // setModalOpen(true)
            return
          }
        }
        const success = await sendTx(() => enter(parsedAmount))
        if (!success) {
          setPendingTx(false)
          // setModalOpen(true)
          return
        } else  {
          setHash(String(success))
        }
      } else if (activeTab === 1) {
        const success = await sendTx(() => leave(parsedAmount))
        if (!success) {
          setPendingTx(false)
          // setModalOpen(true)
          return
        } else {
          setHash(String(success))
        }
      }

      setStakePercent('0');
      setPendingTx(false)
    }
  }



  const handleChangePercent = useCallback((val: string) => {
    const correctVal = val.replace(/[^\d.-]/g, '');
    const num = isNaN(Number(correctVal)) ? 0 : Number(correctVal);
    const correctNum = num < 0 ? 0 : num > 100 ? 100 : num;
    setStakePercent(`${correctNum}`);
  }, []);

  return (
    <>
      <Modal isOpen={!isConfirmationModalOpen}
             title={{firstLine: isRemove ? 'remove' : 'add', secondLine: 'stake'}}
             description={'The property for the staking of the MONEY token in the HODL Valley network.'}
             mainIconName={"Mine"}>
        <div className={`h-full relative`}>
          <div className={`h-full flex flex-col`}>
            <div className={`w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative p-20px rounded-tr-60 ${styles.assetForm}`}>
              <div className={'flex'}>
                <div className={styles.leftColumn}>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>STAKING APR</p>
                    <p className={styles.value}>{apr}%</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>BALANCE</p>
                    <p className={styles.value}>{xMoneyBalance ? xMoneyBalance.toSignificant(4) : '-'}</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>UNSTAKED</p>
                    <p className={styles.value}>{moneyBalance ? moneyBalance.toSignificant(4) : '-'}</p>
                  </div>
                  {/*<div className={styles.infoRow}>*/}
                  {/*  <p className={styles.title}>DATE ADDED</p>*/}
                  {/*  <p className={styles.value}>{DateTime.fromJSDate(stakeData.addedAt).toFormat('LL/d/yyyy')}</p>*/}
                  {/*</div>*/}
                </div>
                <div className={styles.rightColumn}>
                  <div className={'-mt-9px'}>
                    <TokenDropdown
                      disabled={false}
                      operation={isRemove ? 'unstake' : 'stake'}
                      amount={stakePercent}
                      onChangeAmount={handleChangePercent}
                      allowEditAmount={true}
                      size={Sizes.SMALL}
                      type={TokenDropdownTypes.INPUT_STEPPER}
                      suffix={'%'}
                      readonly={false}/>
                  </div>
                  <div className={'flex justify-end mt-7px mr-10px'}>
                    <button onClick={()=>{
                      handleChangePercent('100')
                    }} className={`${styles.yellowBtn}`}>MAX</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`w-full ${styles.receiveForm}`}>
              <div className={`${styles.calcColumn} items-center w-full justify-center`}>
                <p className={'font-medium text-gray-400 text-40px tracking-normal leading-8'}>{parsedAmount ? parsedAmount.toSignificant(4, { groupSeparator: ',' }) : input}</p>
                <p className={'font-medium text-gray-400 text-xl tracking-normal leading-10 mt-6px'}>MONEY</p>
              </div>
              <div className={'flex justify-end items-center mt-38px'}>
                {(approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING) && activeTab === 0 ? (
                    <Button
                        disabled={approvalState === ApprovalState.PENDING}
                        onClick={approve}
                    >
                      {approvalState === ApprovalState.PENDING ? (
                          <Dots>Approving</Dots>
                      ) : (`Approve`)}
                    </Button>
                ) : (
                    <Button
                        onClick={handleClickButton}
                        disabled={buttonDisabled || inputError}
                    >
                      {!walletConnected
                          ? `Connect Wallet`
                          : parseFloat(input) <= 0
                              ? `Enter Amount`
                              : insufficientFunds
                                  ? `Insufficient Balance`
                                  : activeTab === 0
                                      ? `Confirm Staking`
                                      : `Confirm Withdrawal`}
                    </Button>
                )}

              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Confirmation type={TransactionType.ADD_STAKE}
                     isOpen={isConfirmationModalOpen}
                     onDismiss={() => setIsConfirmationModalOpen(false)}
                     submitted={!!hash}
                     chainId={chainId}
                     hash={hash}
                     amount={parsedAmount
                       ? parsedAmount.toSignificant(4, { groupSeparator: ',' })
                       : input} />
    </>
  )
}

export default AddRemoveStakeModal;
