import {ChainId, Currency, CurrencyAmount, Ether, Percent, Trade as V2Trade, TradeType} from '@hodlvalley/sdk'
import React, {useCallback, useMemo} from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
  TransactionType,
} from 'modals/TransactionConfirmationModal'
import SwapModalFooter from './SwapModalFooter'
import SwapModalHeader from './SwapModalHeader'
import HodlConfirmationModal from 'modals/Confirmation'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param args either a pair of V2 trades or a pair of V3 trades
 */
function tradeMeaningfullyDiffers(
  ...args: [V2Trade<Currency, Currency, TradeType>, V2Trade<Currency, Currency, TradeType>]
): boolean {
  const [tradeA, tradeB] = args
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  )
}

export default function ConfirmSwapModal({
  trade,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  minerBribe,
}: {
  isOpen: boolean
  trade: V2Trade<Currency, Currency, TradeType> | undefined
  originalTrade: V2Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent
  minerBribe?: string
  onAcceptChanges: () => void
  onConfirm: () => void
  swapErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const { chainId } = useActiveWeb3React()

  const showAcceptChanges = useMemo(
    () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
    [originalTrade, trade]
  )

  const modalHeader = useCallback(() => {
    return trade ? (
      <SwapModalHeader
        trade={trade}
        allowedSlippage={allowedSlippage}
        recipient={recipient}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
        minerBribe={minerBribe}
      />
    ) : null
  }, [allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, trade])

  const modalBottom = useCallback(() => {
    return trade ? (
      <SwapModalFooter
        onConfirm={onConfirm}
        trade={trade}
        disabledConfirm={showAcceptChanges}
        swapErrorMessage={swapErrorMessage}
      />
    ) : null
  }, [onConfirm, showAcceptChanges, swapErrorMessage, trade])

  // text to show while loading
  const pendingText = `Swapping ${trade?.inputAmount?.toSignificant(6)} ${
    trade?.inputAmount?.currency?.symbol
  } for ${trade?.outputAmount?.toSignificant(6)} ${trade?.outputAmount?.currency?.symbol}`

  const pendingText2 = minerBribe
    ? `Plus ${CurrencyAmount.fromRawAmount(Ether.onChain(ChainId.MAINNET), minerBribe).toSignificant(6)} ETH Miner Tip`
    : undefined

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Swap"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  return (
    <HodlConfirmationModal
      type={TransactionType.SWAP}
      chainId={chainId}
      hash={txHash}

      onDismiss={onDismiss}
      submitted={attemptingTxn ? false : !!txHash}
      isOpen={isOpen}
      token0Symbol={trade?.inputAmount?.currency?.symbol}
      token1Symbol={trade?.outputAmount?.currency?.symbol}

      // token0Amount={Number(trade?.inputAmount?.toFixed(6)).toString()}
      // token1Amount={Number(trade?.outputAmount?.toFixed(6)).toString()}

      token0Amount={trade?.inputAmount}
      token1Amount={trade?.outputAmount}
    />
  )

  // return (
  //   <TransactionConfirmationModal
  //     isOpen={isOpen}
  //     onDismiss={onDismiss}
  //     attemptingTxn={attemptingTxn}
  //     hash={txHash}
  //     // content={confirmationContent}
  //     pendingText={`Swapping ${trade?.inputAmount?.toSignificant(6)} ${
  //       trade?.inputAmount?.currency?.symbol
  //     } for ${trade?.outputAmount?.toSignificant(6)} ${trade?.outputAmount?.currency?.symbol}`}
  //     pendingText2={minerBribe
  //       ? `Plus ${CurrencyAmount.fromRawAmount(Ether.onChain(ChainId.MAINNET), minerBribe).toSignificant(6)} ETH Miner Tip`
  //       : undefined}
  //     currencyToAdd={trade?.outputAmount.currency}
  //
  //     type={TransactionType.SWAP}
  //     token0Symbol={trade?.inputAmount?.currency?.symbol}
  //     token1Symbol={trade?.outputAmount?.currency?.symbol}
  //
  //     token0Amount={trade?.inputAmount?.toSignificant(6)}
  //     token1Amount={trade?.outputAmount?.toSignificant(6)}
  //   />
  // )
}
