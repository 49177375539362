import React, { useCallback, useEffect, useState } from 'react'
import { getAddress } from '@ethersproject/address'
import { Token, ChainId, FARMING_ADDRESS } from '@hodlvalley/sdk'
import { useSelector } from 'react-redux'

import { Button, Modal, Dots } from 'components'
import Input from './Input'

import { AppState } from 'state'
import { useTokenBalance } from 'state/wallet/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

import { formatCurrency } from 'analytics/core/format'

import { formatPercent, formatNumber, tryParseAmount } from 'functions'

import { useActiveWeb3React, useApproveCallback, ApprovalState } from 'hooks'
import { showToast, ToastTypes } from 'hooks/useToast'

import { PairType } from 'features/farm/enum'
import { useUserInfo } from 'features/farm/hooks'
import useMasterChef from 'features/farm/useMasterChef'
import { Chef } from 'features/farm/enum'

import styles from './farmDetails.module.scss'
import TransactionConfirmationModal, {
	ConfirmationModalContent,
	TransactionType
} from 'modals/TransactionConfirmationModal'
import { Field } from 'state/mint/actions'

const FarmDetailsModal: React.FC = () => {
	const { account, chainId } = useActiveWeb3React()
	const [pendingTx, setPendingTx] = useState(false)
	const [balanceAmount, setBalanceAmount] = useState('0')
	const [farmingAmount, setFarmingAmount] = useState('0')

	const { farmData } = useSelector((state: AppState) => state.farm)


	const getFarmConfig = useCallback(() => {
		return {
			title: 'FARM DETAILS',
			infoColumns: [
				{ title: 'Harvesting', value: farmData.balance },
				{ title: 'BASE', value: farmData.pair?.token0?.symbol },
				{ title: 'QUOTE', value: farmData.pair?.token1?.symbol }
			]
		}
	}, [farmData.pair?.token0?.symbol, farmData.pair?.token1?.symbol, farmData.balance])

	const addTransaction = useTransactionAdder()


	const liquidityToken = new Token(
		chainId,
		getAddress(farmData.pair.id),
		farmData.pair.type === PairType.KASHI ? Number(farmData.pair.asset.decimals) : 18,
		farmData.pair.type === PairType.KASHI ? 'KMP' : 'HVLP'
	)

	const typedDepositValue = tryParseAmount(balanceAmount, liquidityToken)
	const typedWithdrawValue = tryParseAmount(farmingAmount, liquidityToken)

	// const pendingSushi = usePendingMoney(farmData)
	// const reward = usePendingReward(farmData)

	const APPROVAL_ADDRESSES = {
		[Chef.MASTERCHEF]: {
			[ChainId.MAINNET]: FARMING_ADDRESS[ChainId.MAINNET],
			[ChainId.ROPSTEN]: FARMING_ADDRESS[ChainId.ROPSTEN]
		}
	}

	const balance = useTokenBalance(account, liquidityToken)
	const amount = useUserInfo(farmData, liquidityToken)

	const [approvalState, approve] = useApproveCallback(typedDepositValue, APPROVAL_ADDRESSES[farmData.chef][chainId])

	const { deposit, withdraw, harvest } = useMasterChef(farmData.chef)

	const handleDismissConfirmation = useCallback(() => {
    setPendingTx(false)
	}, [])

	const handleFarm = useCallback(async () => {
		setPendingTx(true)

		try {
			const tx = await deposit(farmData.id, balanceAmount.toBigNumber(liquidityToken?.decimals))

      debugger
			addTransaction(tx, {
				summary: `Deposit ${farmData.pair?.token0?.symbol}/${farmData.pair?.token1?.symbol}`
			})
		} catch (error) {
			showToast(error.message, {
				type: ToastTypes.error,
				timeout: 7 * 1000
			})
			console.error(error)
		}

		setPendingTx(false)
	}, [farmData, balanceAmount, liquidityToken])

	return (
		<>
			<TransactionConfirmationModal
				type={TransactionType.FARM}
				isOpen={pendingTx}
				onDismiss={handleDismissConfirmation}
				attemptingTxn={true}
				hash={''}
				pendingText={'Farming'}
			/>
			<Modal isOpen={!pendingTx}
						 title={{ firstLine: `farm`, secondLine: 'details' }}
						 description={'The property for the farming of the HVLP token in the HODL Valley network.'}
						 formDetailsConfig={getFarmConfig()}
						 mainIconName={'FarmOne'}>
				<div className={`h-full relative`}>
					<div className={`h-full flex flex-col`}>
						<div
							className={`w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative p-20px rounded-tr-60 ${styles.assetForm}`}>
							{farmData &&
							<div className={'flex'}>
								<div className={styles.leftColumn}>
									<div className={styles.infoRow}>
										<p className={styles.title}>TVL</p>
										<p className={styles.value}>{formatCurrency(farmData.tvl)}</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>Rewards</p>
										<p
											className={styles.value}>{farmData.rewards.map((r) => <>{formatCurrency(r.poolRewardPerMonth * r.rewardPrice)} {r.token}</>)}</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>Staked</p>
										<p className={styles.value}>{formatNumber(farmData.balance ?? 0)} HVLP</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>Apr</p>
										<p className={styles.value}>{formatPercent(farmData?.roiPerYear * 100)}</p>
									</div>
								</div>
								<div className={styles.rightColumn}>
									<div className={styles.infoRow}>
										<p className={styles.title}>Farm</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.value}>{farmData.pair?.token0?.symbol} / {farmData.pair?.token1?.symbol}</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>Harvesting</p>
									</div>
									<div className={styles.infoRow}>
										<p
											className={`font-medium text-xl uppercase tracking-normal ${parseFloat(amount?.toSignificant(6)) > 0 ? 'text-green-400' : 'text-red-400'}`}>
											{parseFloat(amount?.toSignificant(6)) > 0 ? 'YES' : 'NO'}
										</p>
									</div>
								</div>
							</div>
							}
						</div>
						<div className={`w-full relative z-10 ${styles.receiveForm}`}>
							{farmData &&
							<>
								<div className={'flex justify-between items-start'}>
									<Input
										title={`BALANCE   ${formatNumber(balance?.toSignificant(6) ?? 0)} ${liquidityToken.symbol}`}
										value={balanceAmount} onChange={setBalanceAmount}/>
									<Input
										title={`FARMING   ${formatNumber(amount?.toSignificant(6)) ?? 0} ${liquidityToken.symbol}`}
										value={farmingAmount} onChange={setFarmingAmount}/>
								</div>
								<div className={'flex justify-between items-center mt-35px'}>
									{approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING ? (
										<Button disabled={approvalState === ApprovalState.PENDING} onClick={approve}>
											{approvalState === ApprovalState.PENDING ? <Dots>Approving </Dots> : 'Approve'}
										</Button>
									) : (<Button
										disabled={pendingTx || !typedDepositValue || balance?.lessThan(typedDepositValue)}
										onClick={handleFarm}>
										<p className={'tracking-normal text-white-750'}>Farm</p>
									</Button>)}

									<Button
										disabled={pendingTx || !typedWithdrawValue || amount.lessThan(typedWithdrawValue)}
										onClick={async () => {
											setPendingTx(true)
											try {
												const tx = await withdraw(farmData.id, farmingAmount.toBigNumber(liquidityToken?.decimals))
												addTransaction(tx, {
													summary: `Withdraw ${farmData.pair?.token0?.symbol}/${farmData.pair?.token1?.symbol}`
												})
											} catch (error) {
												showToast(error.message, {
													type: ToastTypes.error,
													timeout: 7 * 1000
												})
												console.error(error)
											}

											setPendingTx(false)
										}}
									>
										<p className={'tracking-normal text-white-750'}>Unfarm</p>
									</Button>
								</div>
							</>
							}
						</div>
					</div>
				</div>
			</Modal>
		</>

	)
}

export default FarmDetailsModal
