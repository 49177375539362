import { Modal, FarmItem } from 'components'
import Image from 'next/image'
import { ChainId } from '@hodlvalley/sdk'
import styles from './searchFarm.module.scss'
import { Sizes } from 'types/Sizes'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import useModals from 'state/modals/hooks'
import { PoolModel } from 'types/PoolModel'
import { FarmModel } from 'types/FarmModel'
import useFarm from 'state/farm/hooks'
import { useRouter } from 'next/router'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FixedSizeList } from 'react-window'
import {
	useAlcxPrice,
	useCvxPrice,
	useEthPrice,
	useFarmPairAddresses,
	useMaticPrice,
	useOnePrice,
	useStakePrice,
	useMoneyPairs,
	useMoneyPrice
} from 'services/graph'
import {
	useActiveWeb3React
} from 'hooks'
import { usePositions } from 'features/farm/hooks'
import { Chef, PairType } from 'features/farm/enum'
import { useFuse } from 'hooks'
import useDebounce from 'hooks/useDebounce'
import useReserveContract from "../../features/stake/useReserve";
import {shortenStringAsAddress} from "functions/format";
import {isAddress} from "functions/validate";


interface SearchPoolOrFarmModalProps {
	isMyPool?: boolean;
	farms
}

const SearchFarmModal: React.FC<SearchPoolOrFarmModalProps> = ({ isMyPool = false, farms }) => {
	const router = useRouter()
	const { closeModals, openModal } = useModals()

	const { setFarm } = useFarm()

	const [searchVal, setSearchVal] = useState('')

	const debouncedSearchVal = useDebounce(searchVal, 250)

	const type = router.query.filter as string

	const pairAddresses = useFarmPairAddresses()

	const swapPairs = useMoneyPairs({
		where: {
			id_in: pairAddresses
		}
	})

	const { moneyBalance: moneyReserveBalance } = useReserveContract()
	const totalReserve = moneyReserveBalance? parseFloat(moneyReserveBalance.toSignificant(6)): 0;

	const moneyReserveForMonth = totalReserve * 0.6

	const positions = usePositions()


	const [moneyPrice, ethPrice, maticPrice, alcxPrice, cvxPrice, stakePrice, onePrice] = [
		useMoneyPrice(),
		useEthPrice(),
		useMaticPrice(),
		useAlcxPrice(),
		useCvxPrice(),
		useStakePrice(),
		useOnePrice()
	]


	const compare = useCallback((base = '', param: string): boolean => {
		return base.toLowerCase().includes(param)
	}, [])


	const map = (pool) => {

		// TODO: Account for fees generated in case of swap pairs, and use standard compounding
		// algorithm with the same intervals acrosss chains to account for consistency.
		// For lending pairs, what should the equivilent for fees generated? Interest gained?
		// How can we include this?

		// TODO: Deal with inconsistencies between properties on subgraph
		pool.owner = pool?.owner || pool?.masterChef || pool?.miniChef
		pool.balance = pool?.balance || pool?.slpBalance

		const swapPair = swapPairs?.find((pair) => pair.id === pool.pair)

		const type = PairType.SWAP

		const pair = swapPair

		function getRewards() {

			const poolRewardPerMonth = (pool.allocPoint / pool.owner.totalAllocPoint) * moneyReserveForMonth

			const defaultReward = {
				token: 'MONEY',
				icon: 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sushi.jpg',
				poolRewardPerMonth,
				rewardPrice: moneyPrice
			}
			return  [defaultReward]
		}

		const rewards = getRewards()

		const balance = Number(pool.balance / 1e18)


		const tvl = (balance / Number(swapPair.totalSupply)) * Number(swapPair.reserveUSD)

		const roiPerMonth =
			rewards.reduce((previousValue, currentValue) => {
				return previousValue + currentValue.poolRewardPerMonth * currentValue.rewardPrice
			}, 0) / tvl

		const roiPerYear = roiPerMonth * 12

		const position = positions.find((position) => position.id === pool.id && position.chef === pool.chef)

		return {
			...pool,
			...position,
			pair: {
				...pair,
				decimals: 18,
				type
			},
			balance,
			roiPerMonth,
			roiPerYear,
			rewards,
			tvl,
		}
	}

	const FILTER = {
		portfolio: (farm) => farm?.amount && !farm.amount.isZero(),
		sushi: (farm) => farm.pair.type === PairType.SWAP,
		kashi: (farm) => farm.pair.type === PairType.KASHI,
		'2x': (farm) => farm.chef === Chef.MASTERCHEF_V2 || farm.chef === Chef.MINICHEF
	}

	const data = farms
		.filter((farm) => {
			return (
				(swapPairs && swapPairs.find((pair) => pair.id === farm.pair))
			)
		})
		.map(map)
		.filter((farm) => {
			return type in FILTER ? FILTER[type](farm) : true
		})


	const options = {
		keys: ['pair.id', 'pair.token0.symbol', 'pair.token1.symbol'],
		threshold: 0.4
	}

	const { result, term, search } = useFuse({
		data,
		options
	})

	const selectItem = useCallback((item): void => {
		closeModals()

		const serialized = {
			...item,
			amount: item.amount?.toString()? item.amount?.toString(): '0',
			pendingMoney: item.pendingMoney?.toString()? item.pendingMoney?.toString(): '0'
		}
		setFarm(serialized as FarmModel)
		router.push(`/farm/${isMyPool ? 'myfarm' : 'allfarm'}/${item.id}`)
	}, [closeModals, isMyPool, router])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			return (
				<div key={index} style={style} className={'px-10px'}>
					<FarmItem item={data[index]} onSelect={() => selectItem(data[index])}/>
				</div>
			)
		},
		[selectItem]
	)

  useEffect(() => {
    search(searchVal)
  }, [debouncedSearchVal])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: isMyPool ? 'view my' : 'view all', secondLine: 'farms' }}
					 description={'The property for the farming of the HVLP token in the HODL Valley network.'}
					 size={Sizes.MEDIUM}
					 mainIconName={'FarmOne'}>
			<div className={`h-full flex flex-col ${styles.form}`}>
				<div className={'px-10px'}>
					<div
						className={`w-full h-66px bg-gray-220 border-3 border-white bg-opacity-60 border-opacity-60 rounded-full flex justify-between items-center pl-43px pr-26px pt-2px ${styles.searchWrapper}`}>
						<input className={'bg-transparent font-medium text-xl text-gray-700 placeholder-gray-700'}
									 placeholder={'SEARCH...'}
									 type="text"
									 value={searchVal}
									 onChange={(e) => setSearchVal(e.target.value)}/>
						<div className={'mt-5px'}>
							<Image src={'/icons/iconMagnifier.svg'} width={25} height={25}/>
						</div>
					</div>
				</div>
				<div className={`mt-20px ${styles.listWrapper}`}>
					<FixedSizeList
            outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
						height={408}
						itemData={result}
						itemCount={result.length}
						itemSize={86}
						width={'100%'}
					>
						{RenderRow}
					</FixedSizeList>
				</div>
			</div>
		</Modal>
	)
}

export default SearchFarmModal
