import React, {ReactNode, useMemo} from "react";

import { StatefulToolTip } from "react-portal-tooltip";
import { placePositions } from "types/placePositions";

export interface TooltipProps {
  children: ReactNode;
  message: string | ReactNode;
  place?: placePositions;
  behaviour?: "absolute" | "fixed";
  offset?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
  arrowPlace?: placePositions;
  customStyle?: React.CSSProperties;
}

const style = {
  style: {
    background: '#21314A',
    padding: "17px 15px 13px 15px",
    borderRadius: 10,
    color: "#BDC7D6",
    boxShadow: "none",
    transform: "translateY(8px)"
  },
  arrowStyle: {
    color: '#21314A',
    borderColor: false,
    borderLeftWidth: 11,
    borderRightWidth: 11,
    transform: "translate(-4px, -2px)"
  }
}

const Tooltip: React.FC<TooltipProps> = ({
                                           children,
                                           message,
                                           place = placePositions.top,
                                           arrowPlace = placePositions.center,
                                           offset,
                                           customStyle = {}}) => {
  const correctStyles = useMemo(() => {
    let offsetTop = 8;
    let offsetLeft = 0;

    if (offset?.top) {
      offsetTop += offset?.top;
    }
    if (offset?.bottom) {
      offsetTop -= offset?.bottom;
    }
    if (offset?.left) {
      offsetLeft += offset?.left;
    }
    if (offset?.right) {
      offsetLeft -= offset?.right;
    }
    return {
      ...style,
      style: {
        ...style.style,
        transform: `translate(${offsetLeft}px, ${offsetTop}px)`,
        ...customStyle
      }
    }
  }, [offset, customStyle]);

  return (
    <StatefulToolTip parent={children} position={place} arrow={arrowPlace} style={correctStyles}>
      <p className={'font-medium text-sm text-gray-80 tracking-normal'}>{message}</p>
    </StatefulToolTip>
)};

export default Tooltip;
