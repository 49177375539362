import React, { useCallback } from "react";
import { Sizes } from "../../types/Sizes";
import Image from "next/image";
import {Button, ButtonType} from "../index";

export interface BackButtonProps {
  onClick?: (e) => void;
}

const BackButton: React.FC<BackButtonProps> = ({onClick}) => {
  const handleClick = useCallback((e) => {
    onClick && onClick(e);
  }, [onClick]);

  return (
    <Button type={ButtonType.GHOST}
            size={Sizes.SMALL}
            leftIcon={<Image src={'/icons/arrowLeft.svg'} alt={'Back'} width={16} height={24} />}
            onClick={handleClick}>
      <p className={'pl-16px'}>BACK</p>
    </Button>
  )
}

export default BackButton