import { Modal, ModalMenuItem} from "../../components"
import {ApplicationModal} from "../../state/modals/actions";

import styles from './swapmeet.module.scss'
import {Sizes} from "../../types/Sizes";
import React, {useCallback, useMemo, useState} from "react";
import useModals from "../../state/modals/hooks";
import {SwapMeet} from "../index";
import {useRouter} from "next/router";
import {MenuItemTypeModel} from "../../components/Menu";
import {BackButton} from "../../components";
import {LinkItemModal} from "../../types/LinkItemModal";

interface SwapMeetModalProps {
  swapPageLink: string;
  pairsPageLink: string;
  poolsPageLink: string;
  tokensPageLink: string;
}

const SwapMeetModal: React.FC<SwapMeetModalProps> = ({swapPageLink, pairsPageLink, poolsPageLink, tokensPageLink  }) => {
  const router = useRouter();
  const { closeModals, openModal } = useModals();
  const [showDetail, setShowDetail] = useState(false);

  const list: LinkItemModal[] = useMemo(() => [
    { label: 'swap tokens', type: MenuItemTypeModel.LINK, link: swapPageLink },
    { label: 'view pairs', type: MenuItemTypeModel.LINK, link: pairsPageLink },
    { label: 'view tokens', type: MenuItemTypeModel.LINK, link: tokensPageLink },
    { label: 'view pools', type: MenuItemTypeModel.LINK, link: poolsPageLink },
    { label: 'property details', type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
  ], [pairsPageLink, poolsPageLink, tokensPageLink]);

  const toggleDetails = useCallback(() => {
    setShowDetail(!showDetail);
  }, [showDetail]);

  const selectSwapModal = useCallback((item: LinkItemModal): void => {
    if (item.link) {
      if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
        closeModals();
      }
      if (item.type === MenuItemTypeModel.MODAL) {
        openModal(item.link as ApplicationModal);
      }
      if (item.type === MenuItemTypeModel.LINK) {
        router.push(`${item.link}`);
      }
      if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
        toggleDetails();
      }
    }
  }, [closeModals, openModal, router, toggleDetails]);

  return (
    <Modal isOpen={true}
           title={{firstLine:'swap', secondLine: 'meet'}}
           description={'The property for decentralized digital asset exchange in the HODL Valley network.'}
           size={Sizes.SMALL}
           mainIconName={"SwapMeet"}>
      <div className={`h-full relative`}>
        <div className={`${styles.body} ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
          <div className={`h-full flex flex-col ${styles.form}`}>
            {list.map((item, index) => (
              <div className={'mb-20px'} key={index}>
                <ModalMenuItem label={item.label} onSelect={() => selectSwapModal(item)} />
              </div>
            ))}
          </div>
        </div>
        <div className={`${styles.body} ${styles.bodyDetails} ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <BackButton onClick={toggleDetails} />
          <div className={styles.detailsForm}>
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>NAME</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>Swap Meet</p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>DESCRIPTION</p>
            <p className={`text-gray-400 text-xl tracking-normal mt-8px ${styles.detailDescription}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. </p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>AVAILABLE FOR PURCHASE</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>No</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SwapMeetModal
