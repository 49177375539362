/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useMemo, useCallback } from 'react'
import Image from 'next/image'
import styles from './tokenDropdown.module.scss'
import { Sizes } from 'types/Sizes'
import { shortenStringAsAddress } from 'functions'
import { Button, ButtonType, Tooltip } from '../index'
import Input from './Input'
import { useActiveWeb3React } from 'hooks'
import { FixedSizeList } from 'react-window'

import { Token } from '@hodlvalley/sdk'
import { isAddress } from 'functions/validate'
import CurrencyLogo from '../CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import { useRemoveUserAddedToken, useUserAddedTokens } from 'state/user/hooks'
import { RowBetween, RowFixed } from 'components/Row'
import ExternalLink from 'components/ExternalLink'
import { getExplorerLink } from 'functions/explorer'

interface ManageTokensProps {
	onManageList: () => void;
	onAddCustomToken?: (token: Token) => void;
}

const ManageTokens: React.FC<ManageTokensProps> = ({ onManageList, onAddCustomToken }) => {
	const { chainId } = useActiveWeb3React()

	const [searchQuery, setSearchQuery] = useState<string>('')
	const [shortAddress, setShortAddress] = useState(searchQuery)
	const [focusedAddressInput, setFocusedAddressInput] = useState(false)

	const handleInput = useCallback((input: string) => {
		const checksummedInput = isAddress(input)
		setSearchQuery(checksummedInput || input)
		setShortAddress(shortenStringAsAddress(input))
	}, [])

	// if they input an address, use it
	const searchToken = useToken(searchQuery)

	// all tokens for local lisr
	const userAddedTokens: Token[] = useUserAddedTokens()
	const removeToken = useRemoveUserAddedToken()

	const handleRemoveAll = useCallback(() => {
		if (chainId && userAddedTokens) {
			userAddedTokens.map((token) => {
				return removeToken(chainId, token.address)
			})
		}
	}, [removeToken, userAddedTokens, chainId])

	const Row = useCallback(
		({ data, index, style }) => {
			const token = data[index]

			return (
				<div
					className={'flex items-center justify-between pb-18px border-b-3 border-gray-260 mb-18px last:border-0 last:mb-0'}
					key={index} style={style}>
					<div className={'flex items-center w-8/12'}>
						<CurrencyLogo currency={token} size={32}/>
						<div className={`flex flex-col pl-8px pt-2px ${styles.tokenNameWrapper}`}>
							<div className={'w-full truncate max-w-full'}>
								<Tooltip message={token.name}>
									<span className={'uppercase text-sm text-gray-400 font-bold truncate max-w-full'}>{token.name}</span>
								</Tooltip>
							</div>
							<p className={'uppercase text-15px text-gray-400 font-bold pt-1px'}>{token.symbol}</p>
						</div>
					</div>
					<div className={'flex items-center justify-end pr-10px -mt-9px w-4/12 h-full'}>
						<div className={'mr-24px flex items-center justify-center cursor-pointer'} onClick={() => removeToken(chainId, token.address)}>
							<Image src={'/icons/iconCrossTiny.svg'} width={21} height={21} />
						</div>
						<a className={'mt-5px cursor-pointer'} href={getExplorerLink(chainId, token.address, 'address')} target={"_blank"} rel="noreferrer">
							<Image src={'/icons/iconShare.svg'} width={29} height={29} />
						</a>
					</div>
				</div>
			)
		},
		[chainId, removeToken]
	)

	return (
		<div className={`relative ${styles.formWrapper}`}>
			<div className={'px-35px mt-7px'}>
				<Input value={focusedAddressInput ? searchQuery : shortAddress}
							 onChange={(val) => {
								 handleInput(val)
							 }}
							 placeholder={focusedAddressInput ? '' : 'Enter token contact address…'}
							 onFocus={() => setFocusedAddressInput(true)}
							 onBlur={() => setFocusedAddressInput(false)}/>
			</div>
			<div className={'px-35px mt-26px'}>
				{!userAddedTokens.length && !searchToken &&
				<p className={'uppercase font-bold text-center text-gray-400 text-sm pt-2px'}>NO SEARCH RESULTS.</p>
				}
				{!!userAddedTokens.length &&
				<div className={'flex justify-between'}>
					<p className={'text-sm uppercase text-gray-400 font-bold'}>{userAddedTokens.length} tokens added</p>
					<p className={'text-sm uppercase text-gray-400 font-bold text-right cursor-pointer'}
						 onClick={handleRemoveAll}>remove all</p>
				</div>
				}
			</div>
			<div className={`px-35px mt-8px flex flex-col overflow-y-scroll ${styles.searchFormShort}`}>
				{searchToken &&
				<div
					className={'flex items-center justify-between pb-18px border-b-3 border-gray-260 mb-18px last:border-0 last:mb-0'}>
					<div className={'flex items-center'}>
						<CurrencyLogo currency={searchToken} size={32}/>
						<div className={`flex flex-col pl-8px pt-2px ${styles.tokenNameWrapper}`}>
							<div className={'w-full truncate max-w-full'}>
								<Tooltip message={(searchToken as any)?.name}>
									<span className={'uppercase text-sm text-gray-400 font-bold truncate max-w-full'}>{(searchToken as any)?.name}</span>
								</Tooltip>
							</div>
							<p className={'uppercase text-15px text-gray-400 font-bold pt-1px'}>{(searchToken as any)?.symbol}</p>
						</div>
					</div>
					<div className={'flex items-center pr-10px -mt-9px'}>
						<Button type={ButtonType.GHOST} size={Sizes.EXTRA_SMALL} onClick={() => {
							onAddCustomToken && onAddCustomToken(searchToken)
							handleInput('')
						}}>
							<p className={'tracking-wider'}>Add</p>
						</Button>
					</div>
				</div>
				}
				<FixedSizeList
					width={'100%'}
					height={160}
					itemData={userAddedTokens}
					itemCount={userAddedTokens.length}
					itemSize={70}
				>
					{Row}
				</FixedSizeList>
			</div>
			<div className={`relative mt-20px`}>
				<div className={'flex justify-center'}>
					<Button type={ButtonType.GHOST} size={Sizes.NORMAL} onClick={onManageList}>
						<p className={'tracking-wider'}>Manage lists</p>
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ManageTokens
