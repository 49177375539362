import Modal from "../components/ModalWallet";
import React, {FC, useState} from "react";
import Button from "../components/Button";
import CloseIcon from "../components/CloseIcon";
import {CrossChainState} from "../state/crosschainswap/reducer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../state";
import {get} from 'lodash'
import {ConfirmationPendingContent} from "../modals/TransactionConfirmationModal";
import {Field, typeInput} from "../state/swap/actions";

interface ConfirmCrossChainSwapModalProps {
    isOpen: boolean;
    onDismiss: ()=> any;
    swapDetails: {
        fromAmount: string;
        toAmount: string;

        fromAsset: string;
        toAsset: string
    };
}

export const safelyParseJSON = (json) => {
    let parsed

    try {
        parsed = JSON.parse(json)
    } catch (e) {
        // console.log('ERROR: ', e, json)
        // Oh well, but whatever...
    }

    return parsed
}


export const ConfirmCrossChainSwapModal: FC<ConfirmCrossChainSwapModalProps> = function (props) {
    const dispatch = useDispatch();
    const [isPending, setIsPending] = useState(()=>false);
    const crossChainState: CrossChainState = useSelector<AppState, CrossChainState>((state) => state.crosschainswap)
    const onConfirmCrossChainSwap =  ()=>{
        return new Promise((resolve, reject) => {
            const market = crossChainState.markets.find((m)=>m.from.toUpperCase() ===swapDetails.fromAsset.toUpperCase() && m.to.toUpperCase() === swapDetails.toAsset.toUpperCase());
            if (market) {
                const eventID = Date.now() + '.' + Math.random()
                window.postMessage({
                    id: eventID,
                    type: 'HODL_CROSS_CHAIN_SWAP',
                    data: {
                        from: swapDetails.fromAsset,
                        to: swapDetails.toAsset,
                        amount: swapDetails.fromAmount
                    }
                }, '*')
                window.addEventListener(eventID, (event: any) => {
                    const detail = safelyParseJSON(event.detail)
                    const result = get(detail, 'result', null)
                    const error = get(detail, 'error', null)
                    if (error) {
                        return reject(error)
                    } else {
                        return resolve(result)
                    }
                }, false)
            } else {
                console.log('Market not available')
                return reject('Market not available')
            }

        })
    }
    const handleSwap = async  ()=>{
        setIsPending(true)
        await onConfirmCrossChainSwap()
        setIsPending(false)
        dispatch(typeInput({ field: Field.INPUT, typedValue: '0' }))
        onDismiss()
    }
    const {isOpen, onDismiss, swapDetails} = props;

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
            {isPending ?
                <ConfirmationPendingContent onDismiss={onDismiss} pendingText={'Please wait'} pendingText2={'Creating swap order'} />
                :
                <div style={{ color: "white" }}>
                    <div className="flex justify-end">
                        <CloseIcon onClick={onDismiss} />
                    </div>
                    <h1>{`Please confirm you are exchanging ${swapDetails.fromAmount} ${swapDetails.fromAsset} for ${parseFloat(swapDetails.toAmount).toFixed(6)} ${swapDetails.toAsset}`}</h1>
                    <Button
                        onClick={handleSwap}
                        disabled={false}
                    >
                        Confirm Swap
                    </Button>
                </div>
            }


        </Modal>
    )
}


