import {useReserveContract as useGetReserveContract} from "hooks/useContract";
import {useCallback, useEffect, useMemo, useState} from "react";
import {CurrencyAmount, Token} from "@hodlvalley/sdk";
import {MONEY} from "constants/tokens";
import useActiveWeb3React from "hooks/useActiveWeb3React";

interface ReserveContractPropsInterface {
    moneyBalance: CurrencyAmount<Token>|null
}

export default function useReserveContract() {
    const contract = useGetReserveContract()

    const [reserveContractProps, setReserveContractProps] = useState<ReserveContractPropsInterface>({
        moneyBalance: null
    });

    const {  chainId } = useActiveWeb3React()

    const moneyReserveBalance = useCallback(async ()=>{
        const tx = await contract?.moneyBalance()
        setReserveContractProps({
            moneyBalance: CurrencyAmount.fromRawAmount(MONEY[chainId], tx?.toString())
        })
    }, [contract, chainId])

    useEffect(()=>{
        moneyReserveBalance();
    }, [moneyReserveBalance]);


    return reserveContractProps
}
