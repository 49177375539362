import {BackButton, Modal, ModalMenuItem} from "../../components"

import styles from './communityFarm.module.scss'
import {Sizes} from "../../types/Sizes";
import React, {useCallback, useMemo, useState} from "react";
import useModals from "../../state/modals/hooks";
import {useRouter} from "next/router";
import {LinkItemModal} from "../../types/LinkItemModal";
import {MenuItemTypeModel} from "../../components/Menu";
import {ApplicationModal} from "../../state/modals/actions";
import {useFarms, useGetMyHodlFarms} from "services/graph";

interface FarmLinkItem extends LinkItemModal {
  count?: number;
}



const CommunityFarmModal: React.FC = () => {
  const router = useRouter();
  const { closeModals, openModal } = useModals();

  const [showDetail, setShowDetail] = useState(false);

  const toggleDetails = useCallback(() => {
    setShowDetail(!showDetail);
  }, [showDetail]);

  const myFarms = useGetMyHodlFarms()
  const allFarms = useFarms()

  const selectModal = useCallback((item: LinkItemModal): void => {
    if (item.link) {
      if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
        closeModals();
      }
      if (item.type === MenuItemTypeModel.MODAL) {
        openModal(item.link as ApplicationModal);
      }
      if (item.type === MenuItemTypeModel.LINK) {
        router.push(`${item.link}`);
      }
      if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
        toggleDetails();
      }
    }
  }, [closeModals, openModal, router, toggleDetails]);

  const list = useMemo(()=>{
    return  [
      { label: 'view my farms', type: MenuItemTypeModel.LINK,  link: '/farm/myfarm', count: myFarms.length },
      { label: 'view all farms', type: MenuItemTypeModel.LINK, link: '/farm/allfarm', count: allFarms.length },
      { label: 'property details', type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
    ]
  }, [myFarms.length, allFarms.length]);
  return (
    <Modal isOpen={true}
           title={{firstLine:'community', secondLine: 'farm'}}
           description={'The property for the farming of the HVLP token in the HODL Valley network.'}
           mainIconName={'FarmOne'}
           size={Sizes.SMALL}>
      <div className={`h-full relative`}>
        <div className={`${styles.body} ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
          <div className={`h-full flex flex-col justify-center`}>
            {list.map((item, index) => (
              <div className={'mb-20px'} key={index}>
                <ModalMenuItem label={item.label} onSelect={() => selectModal(item)}
                               suffix={item.hasOwnProperty('count')
                                 ? <p className={"ml-11px text-blue-400 tracking-normal font-medium text-xl"}>{item.count}</p>
                                 : undefined} />
              </div>
            ))}
          </div>
        </div>
        <div className={`${styles.body} ${styles.bodyDetails} ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <BackButton onClick={toggleDetails} />
          <div className={styles.detailsForm}>
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>NAME</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>Community Farm</p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>DESCRIPTION</p>
            <p className={`text-gray-400 text-xl tracking-normal mt-8px ${styles.detailDescription}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. </p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px'} />
            <p className='text-gray-400 text-xl uppercase text-opacity-60'>AVAILABLE FOR PURCHASE</p>
            <p className='text-gray-400 text-xl tracking-normal mt-8px'>No</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CommunityFarmModal;
