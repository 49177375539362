import {Modal, ModalMenuItem} from "../../components"

import styles from './connectWallet.module.scss'
import {Sizes} from "types/Sizes";
import React, {useEffect, useMemo, useState} from "react";
import {SUPPORTED_WALLETS} from "../../constants";
import {isMobile} from "react-device-detect";
import {injected, portis} from "../../connectors";
import {UnsupportedChainIdError, useWeb3React} from "@web3-react/core";
import {AbstractConnector} from "@web3-react/abstract-connector";
import ReactGA from "react-ga";
import {WalletConnectConnector} from "@web3-react/walletconnect-connector";
import {useRouter} from "next/router";
import PerfectScrollbar from "react-perfect-scrollbar";
import {FixedSizeList} from "react-window";

const ConnectWalletModal: React.FC = () => {
  const router = useRouter();
  const { account, activate } = useWeb3React()

  const [pendingWalletName, setPendingWalletName] = useState<string>();
  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()
  const [pendingError, setPendingError] = useState<boolean>()

  // const selectWallet = useCallback((item: LinkItemModal): void => {
  //   setSelectedWallet(item.label)
  // }, []);

  const tryActivation = async (connector: (() => Promise<AbstractConnector>) | AbstractConnector | undefined) => {
    let name = ''
    let conn = typeof connector === 'function' ? await connector() : connector

    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Change Wallet',
      label: name,
    })
    setPendingWallet(conn) // set wallet for pending view
    setPendingWalletName(name);
    // setWalletView(WALLET_VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (conn instanceof WalletConnectConnector && conn.walletConnectProvider?.wc?.uri) {
      conn.walletConnectProvider = undefined
    }

    localStorage.setItem('ACTIVE_WALLET', name === 'Coin Wallet'? 'coin': 'injected')
    conn &&
    activate(conn, undefined, true).catch((error) => {
      if (error instanceof UnsupportedChainIdError) {
        activate(conn) // a little janky...can't use setError because the connector isn't set
      } else {
        setPendingError(true)
      }
    })
  }

  const options = useMemo(() => {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask

    const optionList = Object.keys(SUPPORTED_WALLETS).map((key) => {

      const option = SUPPORTED_WALLETS[key]

      // check for mobile options
      if (isMobile) {
        // disable portis on mobile for now
        if (option.connector === portis) {
          return null
        }

        if (!window.web3 && !window.ethereum && option.mobile) {
          return option;
        }
        return null
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return option;
          } else {
            return null // dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      return option;
    })
    // return optionList.filter(x => x);
    return optionList.filter(x => x && x?.name !== "Coin Wallet" && x?.name !== "Open in Coinbase Wallet").map(x => ({
      ...x,
      name: x.name === 'WalletConnect' ? 'Wallet Connect' : x.name
    }));
  }, []);

  const tryingToConnect = useMemo(() => pendingWalletName && !pendingError, [pendingWalletName, pendingError])

  useEffect(() => {
    if (account) {
      router.push('/')
    }
  }, [account]);

  const RenderRow = React.useCallback(
    ({ index, style, data }) => (
      <div key={index} style={style} className={`${styles.itemWrapper} px-15px`}>
        <ModalMenuItem label={data[index].name} onSelect={() => tryActivation(data[index].connector)} />
      </div>
    ), [tryActivation]
  );


  return (
    <Modal isOpen={true}
           title={{firstLine:'connect', secondLine: 'wallet'}}
           description={'Please connect your favorite wallet to the network to begin using the HODL Valley metaverse.'}
           size={Sizes.MEDIUM}
           mainIconName={"UserHomeOne"}>
      <div className={`h-full flex flex-col ${styles.form}`}>
        <div className={`w-full relative ${styles.changableBlock}`}>
          <div className={`${styles.body} ${tryingToConnect ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
            <div className={`w-full h-full relative`}>
              <FixedSizeList
                outerElementType={(props) => <PerfectScrollbar {...props} />}
                height={430}
                itemData={options}
                itemCount={options.length}
                itemSize={86}
                width={"100%"}
              >
                {RenderRow}
              </FixedSizeList>
            </div>
          </div>
          <div className={`${styles.body} ${!tryingToConnect ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
            <div className={'h-full flex flex-col justify-center pb-30px'}>
              <p className={'font-medium text-40px text-gray-400 tracking-normal pl-6px mb-30px'}>Connecting…</p>
              <ModalMenuItem label={pendingWalletName} disabled={true} />
            </div>
          </div>
        </div>
        <p className={'font-medium text-xs text-gray-400 mt-10px pl-21px tracking-normal'}>
          Connecting a wallet acknowledges that you agree to HODL Valley’s <a className={'underline'} href="https://docs.hodlvalley.com/docs/legal/terms-of-service" rel="noopener noreferrer" target="_blank">Terms of Service</a>, <a className={'underline'} href="https://docs.hodlvalley.com/docs/legal/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>, and <a className={'underline'} href="https://docs.hodlvalley.com/docs/resources/disclaimer" rel="noopener noreferrer" target="_blank">Disclaimer</a>.        </p>
      </div>
    </Modal>
  )
}

export default ConnectWalletModal;
