import React, {useCallback, useMemo, useState} from "react";
import {ChainId, Currency, Percent, WNATIVE} from '@hodlvalley/sdk'
import Image from 'next/image'
import {Contract} from '@ethersproject/contracts'
import {BigNumber} from '@ethersproject/bignumber'
import {TransactionResponse} from '@ethersproject/providers'
import {useRouter} from 'next/router'
import {useSelector} from "react-redux";

import {Button, FormDetailsConfigModel, Modal, TokenDropdown, TokenDropdownTypes} from "components"

import {Sizes} from "types/Sizes";

import {ApprovalState, useApproveCallback, usePairContract, useRouterContract} from "hooks";
import {useCurrency} from "hooks/Tokens";
import {useActiveWeb3React} from "hooks/useActiveWeb3React";
import useTransactionDeadline from "hooks/useTransactionDeadline";
import {useV2LiquidityTokenPermit} from "hooks/useERC20Permit";
import useDebouncedChangeHandler from "hooks/useDebouncedChangeHandler";
import {useGetPair, usePositionCardInformation} from "hooks/Pool";
import {showToast, ToastTypes} from "hooks/useToast";

import {useWalletModalToggle} from "state/application/hooks";
import {useDerivedMintInfo} from "state/mint/hooks";
import {useBurnActionHandlers, useBurnState, useDerivedBurnInfo} from "state/burn/hooks";
import {useUserSlippageToleranceWithDefault} from "state/user/hooks";
import {Field} from "state/burn/actions";
import {useTransactionAdder} from "state/transactions/hooks";
import {AppState} from "state";

import {calculateGasMargin, calculateSlippageAmount, currencyId} from "functions";

import { TransactionType } from "modals/TransactionConfirmationModal";
import {Confirmation} from "modals/index";

import styles from './removePool.module.scss'


const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)
const RemovePoolModal: React.FC = () => {
  const router = useRouter()
  const {poolData} = useSelector((state: AppState) => state.pool);
  const pair = useGetPair(poolData)
  const {
    poolTokenPercentage,
    currency0,
    token0Deposited,
    token1Deposited,
    currency1
  } = usePositionCardInformation({pair })

  const tokens = router.query.tokens
  const [currencyIdA, currencyIdB] = tokens || [undefined, undefined]
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library } = useActiveWeb3React()
  const [tokenA, tokenB] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped], [currencyA, currencyB])

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const { price } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair: _, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE)

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
        ? '0'
        : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
            ? '<1'
            : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
        independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
        independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
        independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  const formDetailsConfig: FormDetailsConfigModel = useMemo(()=>{

    if (tokenA && tokenB ) {
      return {
        title: 'POOL DETAILS',
        infoColumns: [
          {title: 'POOL SHARE', value: poolTokenPercentage
                ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                : '-'},
          {title: 'RATE', value: `1 ${currencyA?.symbol} = ${tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} ${
                currencyB?.symbol
            }`},
          {title: 'RATE', value: `1 ${currencyB?.symbol} = ${tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} ${
                currencyA?.symbol
            }`}
        ]
      }
    }
    return  {
      title: 'POOL DETAILS',
      infoColumns: [
        {title: 'POOL SHARE', value: '-',},
        {title: 'RATE', value: `-`},
        {title: 'RATE', value: `-`}
      ]
    }

  }, [poolTokenPercentage, currencyA?.symbol, tokenA, currencyB?.symbol, tokenB, pair])

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  // router contract
  const routerContract = useRouterContract()

  // allowance handling
  const { gatherPermitSignature, signatureData } = useV2LiquidityTokenPermit(
      parsedAmounts[Field.LIQUIDITY],
      routerContract?.address
  )
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], routerContract?.address)

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    if (chainId !== ChainId.HARMONY && approveCallback) {
      try {
        await approveCallback()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
      (field: Field, typedValue: string) => {
        return _onUserInput(field, typedValue)
      },
      [_onUserInput]
  )

  const onLiquidityInput = useCallback(
      (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
      [onUserInput]
  )
  const onCurrencyAInput = useCallback(
      (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
      [onUserInput]
  )
  const onCurrencyBInput = useCallback(
      (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
      [onUserInput]
  )

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    if (!chainId || !library || !account || !deadline || !router) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB.isNative
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[], args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
        methodNames.map((methodName) =>
            routerContract.estimateGas[methodName](...args)
                .then(calculateGasMargin)
                .catch((error) => {
                  console.error(`estimateGas failed`, methodName, args, error)
                  return undefined
                })
        )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
        BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await routerContract[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
          .then((response: TransactionResponse) => {
            setAttemptingTxn(false)

            addTransaction(response, {
              summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
                  currencyA?.symbol
              } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
            })

            setTxHash(response.hash)


          })
          .catch((error: Error) => {
            showToast(error.message, {
              type: ToastTypes.error,
              timeout: 7 * 1000
            })
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            console.error(error)
          })
    }
  }


  const liquidityPercentChangeCallback = useCallback(
      (value: string) => {
        onUserInput(Field.LIQUIDITY_PERCENT, value)
      },
      [onUserInput]
  )

  const oneCurrencyIsETH = currencyA?.isNative || currencyB?.isNative

  const oneCurrencyIsWETH = Boolean(
      chainId && WNATIVE[chainId] && (currencyA?.equals(WNATIVE[chainId]) || currencyB?.equals(WNATIVE[chainId]))
  )

  const handleSelectCurrencyA = useCallback(
      (currency: Currency) => {
        if (currencyIdB && currencyId(currency) === currencyIdB) {
          router.push(`/remove/${currencyId(currency)}/${currencyIdA}`)
        } else {
          router.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
        }
      },
      [currencyIdA, currencyIdB, router]
  )

  const handleSelectCurrencyB = useCallback(
      (currency: Currency) => {
        if (currencyIdA && currencyId(currency) === currencyIdA) {
          router.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
        } else {
          router.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
        }
      },
      [currencyIdA, currencyIdB, router]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
      parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
      liquidityPercentChangeCallback
  )

  const [removePercent, setRemovePercent] = useState('0');
  const [approving, setApproving] = useState(false);
  const [approved, setApproved] = useState(false);
  const [reviewed, setReviewed] = useState(false);



  const handleChangePercent = useCallback((val: string) => {
    const correctVal = val.replace(/[^\d.-]/g, '');
    const num = isNaN(Number(correctVal)) ? 0 : Number(correctVal);
    const correctNum = num < 0 ? 0 : num > 100 ? 100 : num;
    setRemovePercent(`${correctNum}`);
  }, []);

  return (
    <>
      <Modal isOpen={!showConfirm}
             title={{firstLine:'remove', secondLine: 'pool'}}
             description={'The property for all pools and adding liquidity in the HODL Valley network.'}
             formDetailsConfig={formDetailsConfig}
             mainIconName={'PoolOne'}>
        <div className={`h-full relative`}>
          <div className={`h-full flex flex-col`}>
            <div className={`w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative p-20px rounded-tr-60 ${styles.assetForm}`}>
              {poolData &&
              <div className={'flex'}>
                <div className={styles.leftColumn}>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>Pool Share</p>
                    <p className={styles.value}>{poolTokenPercentage
                      ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                      : '-'}</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>{currency0.symbol} ADDED</p>
                    <p className={styles.value}>{token0Deposited? token0Deposited?.toSignificant(6): '-'}</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p className={styles.title}>{currency1.symbol} ADDED</p>
                    <p className={styles.value}>{token1Deposited? token1Deposited?.toSignificant(6): '-'}</p>
                  </div>
                  {/*<div className={styles.infoRow}>*/}
                  {/*  <p className={styles.title}>DATE ADDED</p>*/}
                  {/*  <p className={styles.value}>{DateTime.fromJSDate(poolData.addedAt).toFormat('LL/d/yyyy')}</p>*/}
                  {/*</div>*/}
                </div>
                <div className={styles.rightColumn}>
                  <div className={'-mt-9px'}>
                    <TokenDropdown
                      disabled={false}
                      operation={'Remove'}
                      amount={innerLiquidityPercentage}
                      onChangeAmount={(val)=>{
                        const percentage = val.replaceAll('.','')
                        if (Number(percentage) > -1) {
                          setInnerLiquidityPercentage(val.replaceAll('.',''))
                        }

                      }}
                      allowEditAmount={true}
                      size={Sizes.SMALL}
                      type={TokenDropdownTypes.INPUT_STEPPER}
                      suffix={'%'}
                      readonly={false}/>
                  </div>
                  <div className={'flex justify-end mt-7px mr-10px'}>
                    <button onClick={()=>{
                      setInnerLiquidityPercentage('100');
                    }} className={`${styles.yellowBtn}`}>MAX</button>
                  </div>
                </div>
              </div>
              }
            </div>
            <div className={`w-full ${styles.receiveForm}`}>
              {poolData &&
              <div className={'flex items-center justify-end'}>
                <div className={`${styles.calcColumn} items-end`}>
                  <p className={'font-medium text-right text-gray-400 text-35px tracking-normal leading-8'}>{formattedAmounts[Field.CURRENCY_A] || '-'}</p>
                  <p className={'font-medium text-right text-gray-400 text-35px tracking-normal leading-10'}>{currency0.symbol}</p>
                </div>
                <div className={'flex items-center justify-center px-72px'}>
                  <Image src={'/icons/plus.svg'} width={44} height={44} alt={'Plus'} />
                </div>
                <div className={`${styles.calcColumn} items-start`}>
                  <p className={'font-medium text-right text-gray-400 text-35px tracking-normal leading-8'}>{formattedAmounts[Field.CURRENCY_B] || '-'}</p>
                  <p className={'font-medium text-right text-gray-400 text-35px tracking-normal leading-10'}>{currency1.symbol}</p>
                </div>
              </div>
              }
              <div className={'flex justify-end items-center mt-53px'}>
                {!reviewed &&
                <div className={'cursor-pointer mr-74px flex items-center'}>
                  {!approved ? (
                    <div className={'flex items-center'}>
                      <p className={'font-medium text-xl text-black tracking-normal mr-22px'}>SETTINGS</p>
                      <Image src={'/icons/iconGear.svg'} width={33} height={33} alt={'Settings'} />
                    </div>
                  ) : (
                    <div className={'flex items-center mr-22px'}>
                      <p className={'uppercase text-xl text-green-350 font-medium tracking-normal mr-10px'}>Approved</p>
                      <Image src={'/icons/iconSuccessDark.svg'} width={25} height={25} alt={'Success'} />
                    </div>
                  )}
                </div>
                }
                {approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING ? <Button
                  onClick={onAttemptToApprove}
                  confirmed={signatureData !== null}
                  disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                >
                  {approval === ApprovalState.PENDING ? (
                    'Approving'
                  ) :  signatureData !== null ? (
                    `Approved`
                  ) : (
                    `Approve`
                  )}
                </Button>:  <Button
                  disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                  onClick={ async () => {
                    setShowConfirm(true)
                    await onRemove();
                  }}
                  error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                >
                  <p className={approving ? 'text-opacity-50' : 'tracking-normal'}>
                    {error || `Confirm Withdrawal`}
                  </p>
                </Button>}

              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Confirmation type={TransactionType.REMOVE_POOL}
                    isOpen={showConfirm}
                    onDismiss={()=>{
                      handleDismissConfirmation()
                    }}
                    submitted={!!txHash}
                    chainId={chainId}
                    hash={txHash ? txHash : ''}
                    token0Symbol={currencyA?.symbol}
                    token1Symbol={currencyB?.symbol}
                    token0Amount={formattedAmounts[Field.CURRENCY_A]}
                    token1Amount={formattedAmounts[Field.CURRENCY_B]}
      />
    </>
  )
}
export default RemovePoolModal;
