import styles from './tokenCard.module.scss'
import Image from "next/image";
import {TokenModel} from "../../types/TokenModel";
import { useCallback, useMemo } from 'react'
import { formatNumber, unwrappedToken } from '../../functions'
import { CurrencyAmount, JSBI, Pair, Percent } from '@hodlvalley/sdk'
import { getTokenIconFromID, getTokenIconFromName } from 'analytics/core/format'
import { useTokenBalance } from 'state/wallet/hooks'
import { useTotalSupply } from 'hooks/useTotalSupply'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'

interface TokenCardProps {
  firstToken?: TokenModel;
  secondToken?: TokenModel;
  pair?: Pair;
  emptyLineConfig?: {
    firstLine: string;
    secondLine: string;
  },
  onAdd?: () => void;
}

const TokenCard: React.FC<TokenCardProps> = ({ firstToken, secondToken, pair, emptyLineConfig, onAdd}) => {
  const { account } = useActiveWeb3React()

  const handleAdd = useCallback(() => {
    !firstToken && onAdd && onAdd();
  }, [onAdd, firstToken]);

  const token0ImageUrl = useMemo(() => {
    return pair ? getTokenIconFromName({ symbol: pair.token0.symbol }) : '';
  }, [ pair ])

  const token1ImageUrl = useMemo(() => {
    return pair ? getTokenIconFromName({ symbol: pair.token1.symbol }) : '';
  }, [pair])

  const currency0 = useMemo(() => {
    return pair ? unwrappedToken(pair.token0) : undefined;
  }, [pair])

  const currency1 = useMemo(() => {
    return pair ? unwrappedToken(pair.token1) : undefined;
  }, [pair])

  const stakedBalance = useMemo(() => {
    return pair ? CurrencyAmount.fromRawAmount(pair.liquidityToken, '0') : 0;
  }, [pair]);

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair?.liquidityToken) || null;
  const totalPoolTokens = useTotalSupply(pair?.liquidityToken) || 0;

  const poolTokenPercentage = useMemo(() => {
    const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

    return  !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined
  }, [totalPoolTokens, stakedBalance, userDefaultPoolBalance]);

  return (
    <div className={`border-3 rounded-full flex items-center px-17px ${styles.wrapper} ${!firstToken || !pair ? 'border-gray-800' : 'bg-white border-gray-260'} ${emptyLineConfig ? `${styles.wrapperEmpty} cursor-pointer` : ''}`}
         onClick={handleAdd}>
      {pair &&
      <>
        <div className={'w-4/12 flex justify-center'}>
          <Image src={token0ImageUrl} alt={currency0?.name} width={32} height={32} />
          <Image src={token1ImageUrl} alt={currency1?.name} width={32} height={32}/>
        </div>
        <div className={'w-8/12 flex flex-col'}>
          <p className={styles.text}>{currency0?.symbol} / {currency1?.symbol}</p>
          <p>{poolTokenPercentage
            ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
            : '-'}</p>
        </div>
      </>
      }
      {firstToken && secondToken &&
        <>
          <div className={'w-4/12 flex justify-center'}>
            <Image src={firstToken.icon} alt="Token" width={32} height={32} />
            <Image src={secondToken.icon} alt="Token" width={32} height={32}/>
          </div>
          <div className={'w-8/12 flex flex-col'}>
            <p className={styles.text}>{firstToken.symbol} / {secondToken.symbol}</p>
            <p className={''}>8.93%</p>
          </div>
        </>
      }
      {firstToken && !secondToken &&
        <>
          <div className={'w-3/12 flex justify-start pl-2px -mt-6px'}>
            <Image src={firstToken.icon} alt="Token" width={32} height={32} />
          </div>
          <div className={'w-9/12 flex flex-col -ml-1px'}>
            <p className={styles.text}>{firstToken.name}</p>
            <div className={'flex mt-1px'}>
              <p className={styles.text}>{firstToken.symbol}</p>
              <p className={'font-medium text-15px text-blue-400 pl-9px'}>{formatNumber(firstToken.balance)}</p>
            </div>
          </div>
        </>
      }
      {!firstToken && !secondToken && !pair &&
        <>
          <div className={'w-3/12 flex justify-start'}>
            <div className={`${styles.circle} ml-1px`}>
              <Image src={'/icons/plusWhite.svg'} alt="Plus" width={14} height={14} />
            </div>
          </div>
          <div className={'w-9/12 flex flex-col pl-1px'}>
            <p className={styles.text}>{emptyLineConfig?.firstLine}</p>
            <p className={`mt-4px ${styles.text}`}>{emptyLineConfig?.secondLine}</p>
          </div>
        </>
      }
    </div>
)}

export default TokenCard
