import styles from './farmItem.module.scss'
import {useCallback} from "react";
import {PoolModel} from "../../types/PoolModel";
import Image from 'next/image'
import {FarmModel} from "../../types/FarmModel";
import {getTokenIconFromName} from "../../analytics/core/format";
import {formatPercent} from "../../functions"

interface FarmItemProps {
    item: FarmModel;
    onSelect?: () => void;
}

const FarmItem: React.FC<FarmItemProps> = ({item, onSelect}) => {
    const handleSelect = useCallback(() => {
        onSelect && onSelect();
    }, [onSelect]);

    const token0ImageUrl = getTokenIconFromName({symbol: item?.pair?.token0?.symbol})
    const token1ImageUrl = getTokenIconFromName({symbol: item?.pair?.token1?.symbol})

    return (
        <div className={`bg-gray-50 h-66px border-3 border-bg-white rounded-full flex items-center cursor-pointer ${styles.btn} transition-opacity text-opacity-60 text-gray-400 hover:text-opacity-100 hover:bg-white`}
             onClick={handleSelect}>
            <div>
                <Image src={token0ImageUrl} width={25} height={25} alt={'Token'} />
            </div>
            <div className={'ml-10px'}>
                <Image src={token1ImageUrl} width={25} height={25} alt={'Token'} />
            </div>
            <p className={'ml-14px uppercase font-medium text-xl text-gray-400 text-opacity-60'}>
                {`${item?.pair?.token0?.symbol} / ${item?.pair?.token1?.symbol} `}</p>
            <p className={'ml-22px font-medium text-xl text-blue-400'}>{formatPercent(item?.roiPerYear * 100)}</p>
        </div>
    )
}

export default FarmItem
