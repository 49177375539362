import styles from './gainerLoser.module.scss'
import {ReactNode, useEffect, useState} from "react";
import {SearchInput, NetworkListDropdown} from "../../components";

interface LayoutProps {
  children: ReactNode;
  gainers?: boolean;
  onChangeKeyword?: (keyword: string) => void;
}

const GainerLoserLayout: React.FC<LayoutProps> = ({ children, gainers, onChangeKeyword }) => {
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    onChangeKeyword && onChangeKeyword(search);
  }, [onChangeKeyword, search])

  return (
    <div className={`flex justify-center pt-80px`}>
      <div className={`${styles.wrapper} border-10 border-white rounded-80 overflow-hidden`}>
        <div className={'flex items-start bg-white bg-opacity-80'}>
          <div className={'w-2/12 h-full h-10'}>

          </div>
          <div className={'w-10/12 pl-25px pt-50px'}>
            <div className={'flex justify-between items-top pr-77px'}>
              <div className={'flex justify-end items-center w-full'}>
                <div className={`${styles.searchWrapper}`}>
                  <SearchInput value={search} onChange={setSearch} />
                </div>
                <div className={`ml-25px rounded-full ${styles.tokenDropdownWrapper}`}>
                  <NetworkListDropdown />
                </div>
              </div>
            </div>
            <h2 className={'uppercase font-medium text-40px text-gray-800 mt-59px mb-14px tracking-normal pl-7px leading-10'}>
              {gainers ? 'Gainers' : 'Losers'}
            </h2>
            <div className={'pb-55px'}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GainerLoserLayout;
