import { Currency, CurrencyAmount } from '@hodlvalley/sdk'
import { useActiveWeb3React } from "../../hooks";
import { useCurrencyBalance } from "../../state/wallet/hooks";
import Loader from '../../components/Loader'

function ShowBalance({ balance }: { balance: CurrencyAmount<Currency> }) {

  return (
    <div className="whitespace-nowrap overflow-hidden max-w-[5rem] overflow-ellipsis text-blue-400 tracking-wider	font-medium text-15px" title={balance.toExact()}>
      {balance.toSignificant(4)}
    </div>
  )
}

const Balance = ({ currency }) => {
  const { account } = useActiveWeb3React();

  const balance = useCurrencyBalance(account ?? undefined, currency)
  return (
    <div className="flex items-center justify-end">
      {balance ? <ShowBalance balance={balance} /> : account ? <Loader /> : null}
    </div>
  )
}

export default Balance;
