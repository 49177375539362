import Image from 'next/image'
import React, { useEffect, useMemo } from 'react'
import { ChainId, Currency, CurrencyAmount } from '@hodlvalley/sdk'

import { Modal, Tooltip, ExternalLink, NotificationType } from 'components'

import { Sizes } from 'types/Sizes'

import { TransactionType } from 'modals/TransactionConfirmationModal'

import { getExplorerLink } from 'functions/explorer'

import styles from './confirmation.module.scss'
import {useRouter} from "next/router";
import useTransactionToast from 'state/transactionToast/hooks'
import { getEtherScanBaseUrl } from 'analytics/core/format'
import { useAppSelector } from 'state/hooks'

interface ModalPropsType {
	isOpen: boolean;
	submitted?: boolean;

	token0Symbol?: string;
	token0Amount?: string | CurrencyAmount<Currency>;

	token1Symbol?: string;
	token1Amount?: string | CurrencyAmount<Currency>;

	onDismiss?: () => void;

	chainId?: ChainId;
	hash?: string;
	type?: TransactionType

  amount?: string;
}

const ConfirmationModal: React.FC<ModalPropsType> = ({ chainId,
                                                       hash,
                                                       isOpen,
                                                       submitted,
                                                       onDismiss,
                                                       token0Amount,
                                                       token0Symbol,
                                                       token1Amount,
                                                       token1Symbol,
                                                       type,
                                                       amount}) => {

	const router = useRouter();

	const { showTransactionToast } = useTransactionToast();

	const state = useAppSelector((state) => state.transactions)

	const transactions = useMemo(() => (chainId ? state[chainId] ?? {} : {}), [chainId, state])

	useEffect(() => {
		if (submitted && hash?.length > 0 && transactions[hash]?.summary) {
			showTransactionToast(hash, {
				text: transactions[hash]?.summary,
				linkText: {
					text: 'View Transaction',
					link: `${getEtherScanBaseUrl(chainId)}tx/${hash}`
				},
				type: NotificationType.NORMAL
			})
		}

		if (submitted && hash?.length > 0 && onDismiss) {
			onDismiss();
			router.push("/")
		}
	}, [submitted, hash, onDismiss, showTransactionToast, getEtherScanBaseUrl, chainId]);


	return (
		<Modal isOpen={isOpen}
			   title={{ firstLine: submitted ? 'SUBMITTED!' : 'waiting...' }}
			   firstLineClassname={`${type && [TransactionType.SWAP, TransactionType.ADD_POOL, TransactionType.REMOVE_POOL, TransactionType.ADD_STAKE].includes(type) && !submitted ? 'mt-16px' : 'mt-48px'}`}
			   headBlock={submitted
				   ? <p
					   className={'font-medium text-25px text-center text-gray-400 opacity-60 tracking-normal mt-9px mb-65px'}>Your
					   request has been submitted to the blockchain!</p>
				   : <p
					   className={'font-medium text-sm text-center text-gray-400 opacity-60 tracking-normal mt-1px mb-27px'}>
					   {type && type === TransactionType.ADD_STAKE
						   ? 'Please confirm this stake transaction in your wallet.'
						   : 'Please confirm this liquidity transaction in your wallet.'}
				   </p>}
			   isConfirmation={true}
			   size={Sizes.EXTRA_SMALL}>
			{submitted ? (
				<div className={'flex justify-center items-center pt-52px'}>
					<p className={'font-bold text-xl text-blue-400 cursor-pointer uppercase tracking-normal'}>
						{chainId && hash && (
							<ExternalLink href={getExplorerLink(chainId, hash, 'transaction')}>
								VIEW TRANSACTION
							</ExternalLink>
						)}
					</p>
				</div>
			) : (
				<>
					{type === TransactionType.ADD_STAKE &&
					<div className={`h-full relative px-50px`}>
						<div className={'flex flex-col justify-center items-center border-b-4 border-gray-100 pt-29px pb-27px'}>
							<p className={'text-gray-400 font-medium text-center text-40px tracking-normal'}>{amount}</p>
							<p className={'text-gray-400 font-medium text-center uppercase text-xl -mt-2px tracking-normal'}>Money</p>
						</div>
						<div className={'flex justify-center items-center pt-37px'} onClick={onDismiss}>
							<p className={'font-bold text-xl text-blue-400 cursor-pointer uppercase tracking-normal'}>Close</p>
						</div>
					</div>
					}
					{[TransactionType.SWAP, TransactionType.ADD_POOL, TransactionType.REMOVE_POOL].includes(type) &&
					<div className={`h-full relative px-16px pt-36px`}>
						<div className={'flex justify-center items-center border-b-4 border-gray-100 pb-33px'}>
							<div className={`flex flex-col items-end pt-4px ${styles.valueColumnWrapper}`}>
								<div className={'w-full text-right'}>
									<Tooltip message={typeof token0Amount === 'string' ? token0Amount : token0Amount?.toSignificant(6)}>
										<p className={`${styles.value} truncate w-full`}>{typeof token0Amount === 'string' ? token0Amount : Number(token0Amount?.toFixed(6))}</p>
									</Tooltip>
									<p className={`${styles.value} mt-4px text-right`}>{token0Symbol}</p>
								</div>
							</div>
							{/*<div*/}
							{/*  className={`${styles.plusWrapper} bg-gray-200 border-8 border-gray-100 flex items-center justify-center rounded-full mx-22px`}>*/}
							{/*  <Image src={'/icons/plus.svg'} alt={'Plus'} width={28} height={28}/>*/}
							{/*</div>*/}
							<div
								className={`${styles.plusWrapper} bg-gray-200 border-8 border-gray-100 flex items-center justify-center rounded-full mx-16px`}>
								{[TransactionType.SWAP, TransactionType.ADD_POOL].includes(type) &&
									<>
										<Image src={'/icons/arrowDown.svg'} className={'rotate-90'} alt={'Plus'} width={28} height={24}/>
										<Image src={'/icons/arrowDown.svg'} className={'-rotate-90'} alt={'Plus'} width={28} height={24}/>
									</>
								}
								{type === TransactionType.REMOVE_POOL &&
									<p className={'text-6xl'}>-</p>
								}
							</div>
							<div className={`flex flex-col items-start pt-4px ${styles.valueColumnWrapper}`}>
								<div className={'w-full'}>
									<Tooltip message={typeof token1Amount === 'string' ? token1Amount : token1Amount?.toSignificant(6)}>
										<p className={`${styles.value} truncate w-full`}>{typeof token1Amount === 'string' ? token1Amount : Number(token1Amount?.toFixed(6))}</p>
									</Tooltip>
								</div>
								<p className={`${styles.value} mt-4px`}>{token1Symbol}</p>
							</div>
						</div>
						<div className={'flex justify-center items-center pt-37px'} onClick={onDismiss}>
							<p className={'font-bold text-xl text-blue-400 cursor-pointer uppercase tracking-normal'}>Close</p>
						</div>
					</div>
					}
				</>
			)}
		</Modal>
	)
}

export default ConfirmationModal
